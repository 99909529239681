<template>
  <b-skeleton
    height="73px"
    class="mb-3"
  />
</template>

<script>
export default {
  name: 'ActorDirectorInput',
};
</script>

<style scoped>

</style>
