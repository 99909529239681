export default {
  methods: {
    formatYoutubeEmbedLink(url) {
      const parts = url.split('/');
      let videoId = parts.pop() || parts.pop();
      if (videoId.indexOf('watch?v=') >= 0) {
        videoId = videoId.replace('watch?v=', '');
      }
      return 'https://youtube.com/embed/' + videoId;
    },

    formatVimeoEmbedLink(url) {
      const parts = url.split('/');
      const videoId = parts.pop() || parts.pop();

      return 'https://player.vimeo.com/video/' + videoId;
    },

    formatIMDBEmbedLink(url) {
      const parts = url.split('/');
      if (parts.indexOf('?ref')) {
        parts.splice(parts.indexOf('?ref'));
      }
      const videoId = parts.pop() || parts.pop();

      return 'https://www.imdb.com/video/imdb/' + videoId + '/imdb/embed?autoplay=false&width=640';
    },

    formatAllocineEmbedLink(url) {
      const videoId = url.slice(url.indexOf('=') + 1, url.indexOf('&'));
      return 'https://player.allocine.fr/' + videoId + '.html';
    },

    /**
     * Takes a link as a parameter and formats it for embedding
     * @param {string} link
     * @return {string|null}
     */
    getEmbedLink(link) {
      if (link !== undefined && link !== null && link !== '') {
        // Youtube
        if (link.indexOf('youtube') >= 0 || link.indexOf('youtu.be') >= 0) {
          return this.formatYoutubeEmbedLink(link);
        }

        // Vimeo
        if (link.indexOf('vimeo') >= 0) {
          return this.formatVimeoEmbedLink(link);
        }

        // IMDB
        if (link.indexOf('imdb') >= 0) {
          return this.formatIMDBEmbedLink(link);
        }

        // Allocine
        if (link.indexOf('allocine') >= 0) {
          return this.formatAllocineEmbedLink(link);
        }

        // Other
        if (link.indexOf('https://') >= 0) {
          return this.formatOtherLink(link);
        }
      }

      return null;
    },

    // Changed this to format null for now to prevent websites being loaded
    formatOtherLink(url) {
      return null;
    },
  },
};
