const MOVIE = 'MO';
const TELEVISION = 'TV';
const SEASON = 'TVSE';
const EPISODE = 'EP';
const ALBUM = 'MA';
const TRACK = 'MT';
const SHORT = 'SH';
const OTHER_VIDEO_ITEM = 'OVI';
const OTHER_AUDIO_ITEM = 'OAI';
const OTHER_AUDIO_GROUP = 'OAG';
const OTHER_AUDIO_GROUP_ITEM = 'OAGI';
const TRAILER = 'TRA';

const AUDIO = [
  ALBUM,
  TRACK,
  OTHER_AUDIO_GROUP,
  OTHER_AUDIO_GROUP_ITEM,
  OTHER_AUDIO_ITEM,
];

const HIERARCHICAL = [
  TELEVISION,
  ALBUM,
  OTHER_AUDIO_GROUP,
];

const VIDEO = [
  MOVIE,
  SHORT,
  TELEVISION,
  SEASON,
  EPISODE,
  TRAILER,
];

/**
 * Class used to make content type constants globally available
 */
export default class ContentTypeCode {
  /**
   * Static getter for the Movie content type;
   * @return {string}
   * @constructor
   */
  static get MOVIE() {
    return MOVIE;
  }

  /**
   * Static getter for the Television content type
   * @return {string}
   * @constructor
   */
  static get TELEVISION() {
    return TELEVISION;
  }

  /**
   * Static getter for the Season content type
   * @return {string}
   * @constructor
   */
  static get SEASON() {
    return SEASON;
  }

  /**
   * Static getter for the Episode content type
   * @return {string}
   * @constructor
   */
  static get EPISODE() {
    return EPISODE;
  }

  /**
   * Static getter for the Album content type
   * @return {string}
   * @constructor
   */
  static get ALBUM() {
    return ALBUM;
  }

  /**
   * Static getter for the Track content type
   * @return {string}
   * @constructor
   */
  static get TRACK() {
    return TRACK;
  }

  /**
   * Static getter for the Short content type
   * @return {string}
   * @constructor
   */
  static get SHORT() {
    return SHORT;
  }

  /**
   * Static getter for the Other Video Item content type
   * @return {string}
   * @constructor
   */
  static get OTHER_VIDEO_ITEM() {
    return OTHER_VIDEO_ITEM;
  }

  /**
   * Static getter for the Other Audio Item content type
   * @return {string}
   * @constructor
   */
  static get OTHER_AUDIO_ITEM() {
    return OTHER_AUDIO_ITEM;
  }

  /**
   * Static getter for the Other Audio Group content type
   * @return {string}
   * @constructor
   */
  static get OTHER_AUDIO_GROUP() {
    return OTHER_AUDIO_GROUP;
  }

  /**
   * Static getter for the Other Audio Group Item content type
   * @return {string}
   * @constructor
   */
  static get OTHER_AUDIO_GROUP_ITEM() {
    return OTHER_AUDIO_GROUP_ITEM;
  }

  /**
   * Static getter for the Trailer content type
   * @return {string}
   * @constructor
   */
  static get TRAILER() {
    return TRAILER;
  }

  /**
   * @return {boolean}
   * @param {string} code
   */
  static isAudio(code) {
    return AUDIO.includes(code);
  }

  /**
   * @param {string} code
   * @return {boolean}
   */
  static isHierarchicalContent(code) {
    return HIERARCHICAL.includes(code);
  }

  /**
   * @return {boolean}
   * @param {string} code
   */
  static isVideo(code) {
    return VIDEO.includes(code);
  }
};
