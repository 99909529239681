import Vue from 'vue';
import {InitStore} from './index';

export default {
  state: {
    datetimeAuthed: null,
    isAirline: null,
    isContentServiceProvider: null,
    isDistributor: null,
    isRefreshingToken: false,
    refreshToken: localStorage.getItem('refreshToken') || '',
    status: '',
    token: localStorage.getItem('token') || '',
    user: {},
  },
  mutations: {
    auth_request(state, user) {
      state.refreshToken = '';
      state.status = 'logging in';
      state.token = '';
      state.user = user;
    },
    auth_success(state, data) {
      state.status = 'logged in';
      state.datetimeAuthed = data.datetimeAuthenticated;
      state.token = data.token;
      state.user = data.user;

      state.isAirline = data.user.isAirline;
      state.isContentServiceProvider = data.user.isContentServiceProvider;
      state.isDistributor = data.user.isDistributor;
    },
    invalidate_success(state) {
      state.token = '';
      state.status = 'token invalidated';
    },
    logout_success(state) {
      state.token = '';
      state.refreshToken = '';
      state.user = {};
      state.status = 'logged out';
    },
    auth_error(state, error) {
      state.token = '';
      state.status = error.message;
    },
    refresh_request(state) {
      state.status = 're-authenticating user';
      state.isRefreshingToken = true;
    },
    refresh_success(state, data) {
      state.status = 'user re-authenticated';
      state.datetimeAuthed = data.datetimeAuthenticated;
      state.token = data.token;
      state.user = data.user;

      if (state.isAirline !== data.user.isAirline) {
        state.isAirline = data.user.isAirline;
      }

      if (state.isContentServiceProvider !== data.user.isContentServiceProvider) {
        state.isContentServiceProvider = data.user.isContentServiceProvider;
      }

      if (state.isDistributor !== data.user.isDistributor) {
        state.isDistributor = data.user.isDistributor;
      }
    },
  },
  actions: {
    login({commit}, payload) {
      return new Promise((resolve, reject) => {
        localStorage.removeItem('token');
        commit('auth_request', {email: payload.email});

        Vue.prototype.$authApi.post('/authentication_token', payload)
            .then((resp) => {
              const token = resp.data.token;
              const refreshToken = resp.data.refresh_token;
              const user = {
                id: resp.data.id,
                name: resp.data.name,
                email: resp.data.emailAddress,
                roles: resp.data.roles,
                person: resp.data.person,
                country: resp.data.country,
                companyType: resp.data.companyType,
                company: resp.data.company,
                permissions: resp.data.permissions,
                isAirline: resp.data.isAirline,
                isContentServiceProvider: resp.data.isContentServiceProvider,
                isDistributor: resp.data.isDistributor,
              };
              const authDatetime = resp.data.authenticatedAt;
              const data = {
                refreshToken: refreshToken,
                token: token,
                user: user,
                datetimeAuthenticated: authDatetime,
              };
              localStorage.setItem('token', `Bearer ${token}`);
              localStorage.setItem('refreshToken', refreshToken);
              Vue.axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
              commit('refresh_token_complete');
              commit('auth_success', data);
              resolve(resp);
            })
            .catch((err) => {
              commit('auth_error', err);
              localStorage.removeItem('token');
              reject(err);
            });
      });
    },
    refreshToken({commit}) {
      const refreshToken = localStorage.getItem('refreshToken');
      if (refreshToken !== null && refreshToken !== '') {
        commit('refresh_request');
        return new Promise((resolve, reject) => {
          Vue.prototype.$authApi.post('/token/refresh', {refresh_token: refreshToken})
              .then((resp) => {
                const token = resp.data.token;
                const refreshToken = resp.data.refresh_token;
                const user = {
                  id: resp.data.id,
                  name: resp.data.name,
                  email: resp.data.emailAddress,
                  roles: resp.data.roles,
                  person: resp.data.person,
                  country: resp.data.country,
                  companyType: resp.data.companyType,
                  company: resp.data.company,
                  companyCode: resp.data.companyCode,
                  companyDisplay: resp.data.companyDisplay,
                  permissions: resp.data.permissions,
                  isAirline: resp.data.isAirline,
                  isContentServiceProvider: resp.data.isContentServiceProvider,
                  isDistributor: resp.data.isDistributor,
                };
                const authDatetime = resp.data.authenticatedAt;
                const data = {
                  refreshToken: refreshToken,
                  token: token,
                  user: user,
                  datetimeAuthenticated: authDatetime,
                };
                localStorage.setItem('token', `Bearer ${token}`);
                localStorage.setItem('refreshToken', refreshToken);
                Vue.axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                commit('refresh_success', data);
                resolve(resp);
              })
              .catch((error) => {
                reject(error);
              })
        }).finally(() => {
          commit('refresh_token_complete');
        });
      }
    },
    logout({commit}) {
      InitStore.removeCache();
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      commit('logout_success');
    },
  },
  getters: {
    isLoggedIn: (state) => {
      return !!state.token;
    },
    authStatus: (state) => {
      return state.status;
    },
    user: (state) => {
      return state.user;
    },
    lastAuthDate: (state) => {
      return state.datetimeAuthed;
    },
    isAirline: (state) => {
      return state.isAirline;
    },
    isContentServiceProvider: (state) => {
      return state.isContentServiceProvider;
    },
    isDistributor: (state) => {
      return state.isDistributor;
    },
    isRefreshingToken: (state) => {
      return state.isRefreshingToken;
    }
  },
};
