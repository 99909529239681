export default {
  state: {
    status: '',
    availabilityTypes: [],
  },
  mutations: {
    availability_type_list_request(state) {
      state.status = 'loading';
    },
    availability_type_list_success(state, availabilityTypes) {
      state.status = 'success';
      state.availabilityTypes = availabilityTypes;
    },
    availability_type_list_error(state) {
      state.status = 'error';
    },
  },
  actions: {
    setAvailabilityTypes({commit}, availabilityTypes) {
      commit('availability_type_list_success', availabilityTypes);
    },
  },
  getters: {
    availableAvailabilityTypes: (state) => {
      return state.availabilityTypes;
    },
    airlineAvailabilityType: (state) => {
      return state.availabilityTypes.filter((type) => {
        return type.code === 'AL';
      })[0];
    },
  },
};
