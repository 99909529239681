export default {
  state: {
    status: '',
    distributors: [],
  },
  mutations: {
    dist_request(state) {
      state.status = 'loading';
    },
    dist_success(state, distributors) {
      state.status = 'success';
      state.distributors = distributors;
    },
    dist_error(state) {
      state.status = 'error';
    },
  },
  actions: {
    setDistributors({commit}, distributors) {
      commit('dist_success', distributors);
    },
  },
  getters: {
    availableDistributors: (state) => {
      return state.distributors;
    },
  },
};
