export default {
  state: {
    status: '',
    audioSampleRates: [],
  },
  mutations: {
    audioSampleRate_request(state) {
      state.status = 'loading';
    },
    audioSampleRate_success(state, sampleRates) {
      state.status = 'success';
      state.audioSampleRates = sampleRates;
    },
    audioSampleRate_error(state) {
      state.status = 'error';
    },
  },
  actions: {
    setAudioSampleRates({commit}, audioSampleRates) {
      commit('audioSampleRate_success', audioSampleRates);
    },
  },
  getters: {
    audioSampleRates: (state) => {
      return state.audioSampleRates;
    },
  },
};
