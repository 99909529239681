import Vue from 'vue';

export default {
  state: {
    status: '',
    actors: [],
  },
  mutations: {
    list_request(state) {
      state.status = 'sending';
    },
    list_success(state, actors) {
      state.status = 'success';
      state.actors = actors;
    },
    list_error(state) {
      state.status = 'error';
    },
  },
  actions: {
    listActors({commit}, params) {
      return new Promise((resolve, reject) => {
        commit('list_request');
        Vue.axios.get('/actors',
            {data: params},
        ).then((response) => {
          commit('list_success', response.data['hydra:member']);
          resolve(response);
        }).catch((error) => {
          commit('list_error');
          reject(error);
        });
      });
    },
  },
  getters: {
    actors: (state) => {
      return state.actors;
    },
  },
};
