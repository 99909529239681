<template>
  <div>
    <span v-if="value">
      <span v-if="field">{{ output[field] }}</span>
      <span v-else>{{ output }}</span>
    </span>
    <span v-else>
      Not Available
    </span>
  </div>
</template>

<script>
export default {
  name: 'DataOrNA',
  props: {
    value: {
      type: [String, Object],
      default: null,
    },
    field: {
      type: String,
      default: null,
    },
  },
  computed: {
    output() {
      if (this.display) {
        return this.value[display];
      }

      return this.value;
    },
  },
};
</script>

<style scoped>

</style>
