const UK = 'UK';
const USA = 'USA';

/**
 * Class used to expose specific country codes
 */
export default class CountryCode {
  /**
   * Static getter for the UK;
   * @return {string}
   * @constructor
   */
  static get UK() {
    return UK;
  }

  /**
   * Static getter for the USA;
   * @return {string}
   * @constructor
   */
  static get USA() {
    return USA;
  }
};
