const AIRLINE = 'Airline';
const CONTENTSERVICEPROVIDER = 'ContentServiceProvider';
const DISTRIBUTOR = 'Distributor';
const RECORDLABEL = 'RecordLabel';
const STUDIO = 'Studio';
const SYSTEMVENDOR = 'SystemVendor';

/**
 * Class used to make company type constants globally available
 */
export default class CompanyType {
  /**
   * Static getter for the Airline company type;
   * @return {string}
   * @constructor
   */
  static get AIRLINE() {
    return AIRLINE;
  }

  /**
   * Static getter for the CSP company type
   * @return {string}
   * @constructor
   */
  static get CONTENTSERVICEPROVIDER() {
    return CONTENTSERVICEPROVIDER;
  }

  /**
   * Static getter for the Distributor company type
   * @return {string}
   * @constructor
   */
  static get DISTRIBUTOR() {
    return DISTRIBUTOR;
  }

  /**
   * Static getter for the Record Label company type
   * @return {string}
   * @constructor
   */
  static get RECORDLABEL() {
    return RECORDLABEL;
  }

  /**
   * Static getter for the Studio company type
   * @return {string}
   * @constructor
   */
  static get STUDIO() {
    return STUDIO;
  }

  /**
   * Static getter for the System Vendor company type
   * @return {string}
   * @constructor
   */
  static get SYSTEMVENDOR() {
    return SYSTEMVENDOR;
  }
};
