<template>
  <div v-if="activeLinks.length">
    <hr>
    <p
      v-for="(link, index) in activeLinks"
      :key="index"
    >
      <router-link
        :class="link.class"
        :to="link.routerInfo"
      >
        {{ link.display }}
      </router-link>
    </p>
  </div>
</template>

<script>
import UserPreferences from '@/Client/Model/UserPreferences';

export default {
  name: 'LoginLinks',
  data() {
    const companyLogin = UserPreferences.getCompanyLogin();
    const cssClass = companyLogin ? companyLogin.cssClass : '';

    const links = [
      {
        display: 'Forgotten your password?',
        routerInfo: {name: 'forgotPassword'},
        active: true,
        class: cssClass,
      },
    ];

    if (
      companyLogin &&
      Object.hasOwn(companyLogin, 'requestAccountEnabled') &&
      companyLogin.requestAccountEnabled
    ) {
      links.unshift({
        display: 'Request Access',
        routerInfo: {name: 'requestAccess'},
        active: true,
        class: cssClass,
      });
    }

    return {
      links: links,
    };
  },
  computed: {
    activeLinks() {
      return this.links.filter((link) => link.active);
    },
  },
};
</script>

<style scoped>

</style>
