export default {
  data() {
    return {
      storedInput: null,
    };
  },
  methods: {
    /**
     * Throw an error from a GraphQL query or mutation (strips the graphql prefix before the toast)
     * @param {Object} error
     */
    graphqlError(error) {
      const message = error.message.replace('GraphQL error: ', '');
      this.toastError(message);
    },
    outputGraphqlDeleteMutationError(error) {
      const h = this.$createElement;
      const errorMessage = error.message.replace('GraphQL error: ', '');
      const messages = errorMessage.split(' | ');

      const vNodeArray = [
        h('ul'),
      ];

      messages.forEach((message) => {
        vNodeArray.push(h('li', message));
      });

      const vNodeMessage = h(
          'ul',
          vNodeArray,
      );

      this.$bvToast.toast([vNodeMessage], {
        variant: 'danger',
        title: 'An error has occurred',
        solid: true,
      });
    },
    /**
     * Retrieves any stored input values
     * @return {Object}
     */
    retrieveInput() {
      const input = _.cloneDeep(this.storedInput);
      this.storedInput = null;
      return input;
    },
    /**
     * Stores input values from a form where the component may have been destroyed due to a loading panel
     * @param {Object} input
     */
    storeInput(input) {
      this.storedInput = input;
    },
    /**
     * Throws a toast with the danger variant
     * @param {string|null} message
     */
    toastError(message) {
      if (null === message || '' === message) {
        message = 'Please contact an administrator';
      }

      const options = {
        title: 'An error has occurred!',
        solid: true,
        variant: 'danger',
      };

      this.$bvToast.toast(message, options);
    },
    /**
     * Throws a toast with the success variant
     * @param {string|null} message
     */
    toastSuccess(message) {
      if (null === message || '' === message) {
        message = 'The operation has been completed';
      }

      const options = {
        title: 'Operation successful!',
        solid: true,
        variant: 'success',
      };

      this.$bvToast.toast(message, options);
    },
    /**
     * Throws a toast with the warning variant
     * @param {string|null} message
     */
    toastWarning(message) {
      if (null === message || '' === message) {
        message = 'Something is not quite right';
      }

      const options = {
        title: 'Warning!',
        solid: true,
        variant: 'warning',
      };

      this.$bvToast.toast(message, options);
    },
    /**
     * Throws a toast with the info variant
     * @param {string|null} message
     */
    toastInfo(message) {
      if (null === message || '' === message) {
        message = 'Something is not quite right';
      }

      const options = {
        title: 'Info',
        solid: true,
        variant: 'info',
      };

      this.$bvToast.toast(message, options);
    },
  },
};
