export default {
  state: {
    status: '',
    territories: [],
    areas: [],
    continents: [],
    countries: [],
    provinces: [],
  },
  mutations: {
    area_request(state) {
      state.status = 'loading';
    },
    area_success(state, areas) {
      state.status = 'success';
      state.areas = areas;
    },
    area_error(state) {
      state.status = 'error';
    },
    continent_request(state) {
      state.status = 'loading';
    },
    continent_success(state, continents) {
      state.status = 'success';
      state.continents = continents;
    },
    continent_error(state) {
      state.status = 'error';
    },
    country_request(state) {
      state.status = 'loading';
    },
    country_success(state, countries) {
      state.status = 'success';
      state.countries = countries;
    },
    country_error(state) {
      state.status = 'error';
    },
    province_request(state) {
      state.status = 'loading';
    },
    province_success(state, provinces) {
      state.status = 'success';
      state.provinces = provinces;
    },
    province_error(state) {
      state.status = 'error';
    },
    territory_request(state) {
      state.status = 'loading';
    },
    territory_success(state, territories) {
      state.status = 'success';
      state.territories = territories;
    },
    territory_error(state) {
      state.status = 'error';
    },
  },
  actions: {
    setTerritories({commit}, territories) {
      commit('territory_success', territories);
    },
    setAreas({commit}, areas) {
      commit('area_success', areas);
    },
    setContinents({commit}, continents) {
      commit('continent_success', continents);
    },
    setCountries({commit}, countries) {
      commit('country_success', countries);
    },
    setProvinces({commit}, provinces) {
      commit('province_success', provinces);
    },
  },
  getters: {
    availableTerritories: (state) => {
      return state.territories;
    },
    availableAreas: (state) => {
      return state.areas;
    },
    availableContinents: (state) => {
      return state.continents;
    },
    availableCountries: (state) => {
      return state.countries;
    },
    availableProvinces: (state) => {
      return state.provinces;
    },
  },
};
