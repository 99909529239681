<template>
  <div
    v-if="companyLogin"
    id="main"
    :class="companyLogin.cssClass"
  >
    <ife-row
      align-h="center"
    >
      <ife-col
        cols="12"
        md="6"
        lg="5"
        xl="3"
        class="mt-4 text-center"
      >
        <img
          v-if="hasCompanyLogo"
          id="companyLogo"
          alt="Company Logo"
          :class="companyLogin.cssClass"
          :src="getCompanyLogo"
        >
        <b-overlay :show="states.login.loading">
          <div
            id="login-card"
            class="p-3"
            :class="`text-center ${companyLogin.cssClass}`"
          >
            <b-alert
              variant="danger"
              :show="states.login.error !== null"
            >
              {{ states.login.error }}
            </b-alert>

            <validation-observer v-slot="v">
              <ife-form
                ref="loginForm"
                @submit="v.handleSubmit(login)"
              >
                <ife-simple-input
                  v-model="credentials.email"
                  :label-cols="4"
                  hide-label
                  label="Email Address"
                  type="email"
                  placeholder="Email address"
                  rules="required"
                />

                <ife-simple-input
                  v-model="credentials.password"
                  :label-cols="4"
                  hide-label
                  label="Password"
                  type="password"
                  placeholder="Password"
                  rules="required"
                />

                <img
                  v-if="isUsingImageButton"
                  :class="`button ${companyLogin.cssClass}`"
                  src="@/assets/img/btn/ato-login.svg"
                  @click="$refs.loginForm.submit()"
                >
                <b-btn
                  v-show="!isUsingImageButton"
                  size="sm"
                  variant="primary"
                  type="submit"
                  :class="companyLogin.cssClass"
                >
                  Log In
                </b-btn>
              </ife-form>
            </validation-observer>
            <login-links />
          </div>

          <template #overlay>
            <div class="text-center">
              <b-icon
                icon="shield-lock"
                font-scale="3"
                animation="cylon"
              />
              <p id="label">
                Authenticating
              </p>
            </div>
          </template>
        </b-overlay>
      </ife-col>
    </ife-row>
  </div>
</template>

<script>
import {InitStore} from '@/Client/Store';
import UserPreferences from '@/Client/Model/UserPreferences';
import LoginLinks from '@/Client/Component/Common/LoginLinks.vue';

export default {
  name: 'CompanyLogin',
  components: {LoginLinks},
  data() {
    return {
      credentials: {
        companyId: null,
        email: null,
        password: null,
      },
      states: {
        login: {
          loading: false,
          error: null,
        },
      },
    };
  },
  computed: {
    companyLogin() {
      return UserPreferences.getCompanyLogin();
    },
    getCompanyLogo() {
      return require(
          '../../../assets/img/logo/' +
            this.companyLogin.logo,
      );
    },
    hasCompanyLogo() {
      return this.companyLogin.hasOwnProperty('logo');
    },
    isUsingImageButton() {
      return 'atallorder' === this.companyLogin.name;
    },
  },
  mounted() {
    if (null === this.companyLogin) {
      this.$router.push({name: 'login'});
    }

    this.credentials.companyId = this.companyLogin.id;

    if (this.companyLogin.windowTitle) {
      document.title = this.companyLogin.windowTitle;
    }

    if ('undefined' !== typeof this.companyLogin.favicon) {
      this.setFavicon(require('../../../assets/img/favicon/' + this.companyLogin.favicon));
    }
  },
  methods: {
    login() {
      this.states.login.error = null;
      this.states.login.loading = true;
      this.$store.dispatch(
          'login',
          this.credentials,
      ).then(() => {
        InitStore.init(this.axios, this.$store);
        this.$router.push({name: this.companyLogin.route, params: {id: this.companyLogin.id}});
      }).catch((error) => {
        this.$log.error(error);
        this.states.login.loading = false;
        this.states.login.error = 'Invalid email or password';
      });
    },
  },
};
</script>

<style scoped>
  #main {
    background-attachment: fixed;
    height: 100vh;
  }

  #companyLogo {
    padding-top: 70px;
    padding-bottom: 50px;
    width: 300px;
  }

  .button:hover {
    cursor: pointer;
  }
</style>
