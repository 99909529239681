// Items
import User from '@/Client/Store/User';
import AppState from '@/Client/Store/AppState';

// Collections
import Language from '@/Client/Store/Collection/Language';
import VersionType from '@/Client/Store/Collection/VersionType';
import Genre from '@/Client/Store/Collection/Genre';
import ContentType from '@/Client/Store/Collection/ContentType';
import Territory from '@/Client/Store/Collection/Territory';
import Country from '@/Client/Store/Collection/Country';
import Distributor from '@/Client/Store/Collection/Distributor';
import Actor from '@/Client/Store/Collection/Actor';
import Director from '@/Client/Store/Collection/Director';
import AspectRatio from '@/Client/Store/Collection/AspectRatio';
import AvailabilityType from '@/Client/Store/Collection/AvailabilityType';
import EntityState from '@/Client/Store/Collection/EntityState';
import AirlineContact from '@/Client/Store/Collection/AirlineContact';
import ContentServiceProviderContact from '@/Client/Store/Collection/ContentServiceProviderContact';
import DistributorContact from '@/Client/Store/Collection/DistributorContact';
import Studio from '@/Client/Store/Collection/Studio';
import EntityType from '@/Client/Store/Collection/EntityType';
import PersonType from '@/Client/Store/Collection/PersonType';
import VideoEncoding from '@/Client/Store/Collection/VideoEncoding';
import VideoBitrate from '@/Client/Store/Collection/VideoBitrate';
import AudioBitrate from '@/Client/Store/Collection/AudioBitrate';
import AudioChannel from '@/Client/Store/Collection/AudioChannel';
import AudioSampleRate from '@/Client/Store/Collection/AudioSampleRate';
import AudioEncoding from '@/Client/Store/Collection/AudioEncoding';
import DisplayResolution from '@/Client/Store/Collection/DisplayResolution';
import Currency from '@/Client/Store/Collection/Currency';
import ImageFormat from '@/Client/Store/Collection/ImageFormat';
import FileExtension from '@/Client/Store/Collection/FileExtension';
import DataSource from '@/Client/Store/Collection/DataSource';
import SelectionType from '@/Client/Store/Collection/SelectionType';
import Lab from '@/Client/Store/Collection/Lab';
import DeliveryMethod from '@/Client/Store/Collection/DeliveryMethod';
import ContentServiceProvider from '@/Client/Store/Collection/ContentServiceProvider';
import Image from '@/Client/Store/Collection/Image';
import moment from 'moment';

import cloneDeep from 'lodash/cloneDeep';
import Vuex from 'vuex';
import Vue from 'vue';
import StateSentiment from '@/Client/Store/Collection/StateSentiment';
import OtherContentType from '@/Client/Store/Collection/OtherContentType';
import ContentServiceProviderAirlines from '@/Client/Store/Collection/ContentServiceProviderAirlines';
import UserCompanyConfigOptions from '@/Client/Store/Collection/UserCompanyConfigOptions';
import OrderAsContentTypes from '@/Client/Store/Collection/OrderAsContentTypes';
import ImageFileExtension from '@/Client/Store/Collection/ImageFileExtension';

Vue.use(Vuex);

// Initial store with modules as an object
export const initialStoreModules = {
  user: User,
  language: Language,
  versionType: VersionType,
  genres: Genre,
  contentType: ContentType,
  territory: Territory,
  country: Country,
  // Remove
  contentServiceProvider: ContentServiceProvider,
  distributor: Distributor,
  // Remove
  actor: Actor,
  // Remove
  director: Director,
  aspectRatio: AspectRatio,
  availabilityType: AvailabilityType,
  entityState: EntityState,
  // Remove
  airlineContact: AirlineContact,
  // Remove
  contentServiceProviderContact: ContentServiceProviderContact,
  // Remove
  distributorContact: DistributorContact,
  appState: AppState,
  // Remove
  studio: Studio,
  entityType: EntityType,
  personType: PersonType,
  videoEncodings: VideoEncoding,
  videoBitrates: VideoBitrate,
  audioBitrates: AudioBitrate,
  audioChannels: AudioChannel,
  audioSampleRates: AudioSampleRate,
  audioEncodings: AudioEncoding,
  displayResolutions: DisplayResolution,
  currencies: Currency,
  imageFormats: ImageFormat,
  imageFileExtensions: ImageFileExtension,
  fileExtensions: FileExtension,
  dataSources: DataSource,
  selectionTypes: SelectionType,
  // Remove
  labs: Lab,
  deliveryMethods: DeliveryMethod,
  stateSentiments: StateSentiment,
  otherContentTypes: OtherContentType,
  contentServiceProviderAirlines: ContentServiceProviderAirlines,
  userCompanyConfigOptions: UserCompanyConfigOptions,
  orderAsContentTypes: OrderAsContentTypes,
  image: Image,
};

const store = new Vuex.Store({
  /**
   * Assign the modules to the store
   * using lodash deepClone to avoid changing the initial store module values
   */
  modules: cloneDeep(initialStoreModules),
  mutations: {
    // reset default state using the state from initialStoreModules
    resetState(state) {
      for (const key of Object.keys(initialStoreModules)) {
        state[key] = cloneDeep(initialStoreModules[key].state);
      }
    },
  },
});

// eslint-disable-next-line require-jsdoc
export class InitStore {
  static init(axios, store) {
    const self = this;

    const cached = self.getCacheFromStorage();

    if (self.isCacheValid(cached)) {
      self.dispatchStore(cached);

      store.dispatch('versionTypes');

      store.dispatch('setAppDataComplete');

      return;
    }

    if (store.getters.appDataLoading || !store.getters.appLoading) {
      return;
    }

    store.dispatch('setAppDataLoading');

    axios.get(process.env.CORE_API_URL + '/app/data').then((resp) => {
      const expiry = moment().add(2, 'hours').format('YYYY-MM-DD HH:mm:ss');

      const cached = {
        'methods': {
          'setStateSentiments': resp.data.stateSentiments,
          'setLanguages': resp.data.languages,
          'setTerritories': resp.data.territories,
          'setAreas': resp.data.areas,
          'setContinents': resp.data.continents,
          'setCountries': resp.data.countries,
          'setProvinces': resp.data.provinces,
          'setDistributors': resp.data.distributors,
          'setContentServiceProviders': resp.data.contentServiceProviders,
          'setLabs': resp.data.labs,
          'setContentTypes': resp.data.contentTypes,
          'setGenres': resp.data.all_genres,
          'setMovieGenres': resp.data.movie_genres,
          'setTelevisionGenres': resp.data.television_genres,
          'setSeasonGenres': resp.data.season_genres,
          'setEpisodeGenres': resp.data.episode_genres,
          'setAlbumGenres': resp.data.album_genres,
          'setTrackGenres': resp.data.track_genres,
          'setShortGenres': resp.data.short_genres,
          'setOtherVideoItemGenres': resp.data.other_video_item_genres,
          'setOtherAudioGroupGenres': resp.data.other_audio_group_genres,
          'setOtherAudioGroupItemGenres': resp.data.other_audio_group_item_genres,
          'setOtherAudioItemGenres': resp.data.other_audio_item_genres,
          'setEntityStates': resp.data.entityStates,
          'setAvailabilityTypes': resp.data.availabilityTypes,
          'setStudios': resp.data.studios,
          'setEntityTypes': resp.data.entityTypes,
          'setPersonTypes': resp.data.personTypes,
          'setVideoEncodings': resp.data.videoEncodings,
          'setVideoBitrates': resp.data.videoBitrates,
          'setAspectRatios': resp.data.aspectRatios,
          'setAudioBitrates': resp.data.audioBitrates,
          'setAudioEncodings': resp.data.audioEncodings,
          'setDisplayResolutions': resp.data.displayResolutions,
          'setAudioChannels': resp.data.audioChannels,
          'setAudioSampleRates': resp.data.audioSampleRates,
          'setCurrencies': resp.data.currencies,
          'setDataSources': resp.data.dataSources,
          'setImageFileExtensions': resp.data.imageFileExtensions,
          'setImageFormats': resp.data.imageFormats,
          'setFileExtensions': resp.data.fileExtensions,
          'setSelectionTypes': resp.data.selectionTypes,
          'setDeliveryMethods': resp.data.deliveryMethods,
          'setOtherContentTypes': resp.data.otherContentTypes,
          'setContentServiceProviderAirlines': resp.data.cspAirlines ?? [],
          'setUserCompanyConfigOptions': resp.data.userCompanyConfigOptions,
          'setOrderAsContentTypes': resp.data.orderAsContentTypes ?? [],
          'setImageTypes': resp.data.imageTypes ?? [],
          'setImageVisibilityTypes': resp.data.imageVisibilityTypes ?? [],
        },
        'expires': expiry,
      };

      self.setCacheInStorage(cached);

      self.dispatchStore(cached);

      store.dispatch('versionTypes');

      store.dispatch('setAppDataComplete');
    }).catch((error) => {
      console.error('Error populating data store:', error);

      // if the response is a 401 error and there's expired cache, use the expired cache
      if (401 === error?.response?.status && this.isCacheExpired(cached)) {
        self.dispatchStore(cached);
        store.dispatch('versionTypes');
        store.dispatch('setAppDataComplete');
        return;
      }

      self.setCacheInStorage({
        'expires': '1900-01-01 00:00:00',
        'methods': {},
      });

      store.dispatch('setAppDataComplete');
      store.dispatch('setAppDataError', error);
    });
  }

  static dispatchStore(cached) {
    if (!Object.hasOwn(cached, 'methods')) {
      return;
    }

    const methods = cached.methods;

    for (const key in methods) {
      if (Object.hasOwn(methods, key)) {
        store.dispatch(key, methods[key]);
      }
    }
  }

  static getCacheFromStorage() {
    const value = localStorage.getItem(this.getKey());

    if (!value) {
      return null;
    }

    return JSON.parse(value);
  }

  static getKey() {
    return 'cachedStore';
  }

  static isCacheValid(cache) {
    if (!cache) {
      return false;
    }

    if (!Object.hasOwn(cache, 'expires')) {
      return false;
    }

    return moment().isBefore(cache.expires);
  }

  static isCacheExpired(cache) {
    if (!cache) {
      return false;
    }

    if (!Object.hasOwn(cache, 'expires')) {
      return false;
    }

    return !moment().isBefore(cache.expires);
  }

  static removeCache() {
    localStorage.removeItem(this.getKey());
  }

  static setCacheInStorage(cachedStore) {
    localStorage.setItem(this.getKey(), JSON.stringify(cachedStore));
  }
}

export default store;
