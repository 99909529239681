const COMINGOFF = 'Coming Off';
const HELD = 'Held';
const NEW = 'New';

/**
 * Class used for global retrieval of Selection states
 */
export default class SelectionState {
  /**
   * Static getter for Selection state 'Coming Off'
   * @return {string}
   */
  static get COMINGOFF() {
    return COMINGOFF;
  }

  /**
   * Static getter for Selection state 'Held'
   * @return {string}
   */
  static get HELD() {
    return HELD;
  }

  /**
   * Static getter for Selection state 'New'
   * @return {string}
   */
  static get NEW() {
    return NEW;
  }
}
