import ApiAdapter from '@/Client/Mixin/ApiAdapter';
import Boolean from '@/Client/Mixin/Boolean';
import Constants from '@/Client/Mixin/Constants';
import CompanyOwnership from '@/Client/Mixin/CompanyOwnership';
import ContentCover from '@/Client/Mixin/ContentCover';
import Copy from '@/Client/Mixin/Copy';
import Currency from '@/Client/Mixin/Currency';
import DateMixin from '@/Client/Mixin/Date';
import EmbedMixin from '@/Client/Mixin/Embed';
import Favicon from '@/Client/Mixin/Favicon';
import FeedbackMixin from '@/Client/Mixin/Feedback';
import IriAdapter from '@/Client/Mixin/IriAdapter';
import Permissions from '@/Client/Mixin/Permissions';
import Resolve from '@/Client/Mixin/Resolve';
import Navigation from '@/Client/Mixin/Navigation';
import Soundtracks from '@/Client/Mixin/Soundtracks';
import StateSentiment from '@/Client/Mixin/StateSentiment';
import Subdomain from '@/Client/Mixin/Subdomain';
import Subtitles from '@/Client/Mixin/Subtitles';
import Time from '@/Client/Mixin/Time';
import ValidationMixin from '@/Client/Mixin/Validation';

const mixin = {
  computed: {
    ...Constants.computed,
  },
  methods: {
    ...ApiAdapter.methods,
    ...Boolean.methods,
    ...CompanyOwnership.methods,
    ...ContentCover.methods,
    ...Copy.methods,
    ...Currency.methods,
    ...DateMixin.methods,
    ...EmbedMixin.methods,
    ...Favicon.methods,
    ...FeedbackMixin.methods,
    ...IriAdapter.methods,
    ...Permissions.methods,
    ...Resolve.methods,
    ...Navigation.methods,
    ...Soundtracks.methods,
    ...StateSentiment.methods,
    ...Subdomain.methods,
    ...Subtitles.methods,
    ...Time.methods,
    ...ValidationMixin.methods,
  },
};

export default mixin;
