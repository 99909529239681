export default {
  methods: {
    canAddAvailability(user, content, permission = 'ROLE_AVAILABILITY_RESOURCE_CREATE') {
      if (this.isPrimaryOwnedButNotByUser(content, user)) {
        return false;
      }

      return this.hasPermission(permission);
    },
    canAddContentVersion(user, content, permission = 'ROLE_CONTENTVERSION_RESOURCE_CREATE') {
      if (this.isPrimaryOwnedButNotByUser(content, user)) {
        return false;
      }

      return this.hasPermission(permission);
    },
    canEditContent(user, content, permission = 'ROLE_CONTENT_CONTENT_RESOURCE_UPDATE') {
      if (this.hasPermission('ROLE_SUPERUSER')) {
        return true;
      }

      if (!content) {
        return false;
      }

      if (this.isPrimaryOwnedButNotByUser(content, user)) {
        return false;
      } else if (this.hasDataOwnerButNotByUser(content, user)) {
        return this.isExceptionAllowed();
      }

      return this.hasPermission(permission);
    },
    hasDataOwnerButNotByUser(content, user) {
      return this.hasDataOwners(content)
          && !this.isAnOwnerByCompany(user, content.dataOwners)
    },
    hasDataOwners(content) {
      return !!content && Object.hasOwn(content, 'dataOwners') && null !== content.dataOwners;
    },
    hasPermission(permission, user) {
      if (user === undefined) {
        user = this.$store.getters.user;
      }

      if (user === undefined || !user.hasOwnProperty('permissions') || user.permissions.length === 0) {
        return false;
      }

      return user.permissions.indexOf(permission) > -1;
    },
    isAnOwnerByCompany(user, dataOwners) {
      let owners = dataOwners;

      if (Object.hasOwn(dataOwners, 'collection')) {
        owners = dataOwners.collection;
      }

      const dataOwnersIri = owners.map((owner) => {
        if ('string' === typeof owner) {
          return owner;
        }

        if (Object.hasOwn(owner, 'id')) {
          return owner.id;
        }

        return null;
      });

      return dataOwnersIri.includes(user.company);
    },
    isExceptionAllowed() {
      return this.user.company === '/api/airlines/3a3b1293-fd00-47bf-9bea-6e7a33f1ebb4';
    },
    isInCompany(user, companyId) {
      // We compare only the strings after the (potential) final slash so that
      // this method is agnostic to whether the full IRI is provided or just the UUID
      return this.hasPermission('ROLE_ADMIN', user) ||
          user.company.split('/').pop() === companyId.split('/').pop();
    },
    isPrimaryOwned(content) {
      return !Object.hasOwn(content, 'primaryOwner') || null !== content.primaryOwner;
    },
    isPrimaryOwnedButNotByUser(content, user) {
      return Object.hasOwn(content, 'id')
          && this.isPrimaryOwned(content)
          && !this.isUserFromPrimaryOwner(user, content.primaryOwner);
    },
    isUserFromPrimaryOwner(user, primaryOwner) {
      if (!primaryOwner) {
        return false;
      }

      if ('object' !== typeof primaryOwner) {
        primaryOwner = {id: primaryOwner};
      }

      return this.isAnOwnerByCompany(user, [primaryOwner]);
    },
  },
};
