import moment from 'moment';

function isDefaultDate(value) {
  return moment.utc(new Date(value)).format('MMM YYYY') === 'Jan 1900';
}

function isTbcDate(value) {
  return moment.utc(new Date(value)).format('YYMMDD') === '991231';
}

export default {
  methods: {
    formatStartDate(date) {
      if (date) {
        if (isDefaultDate(date)) {
          return 'Available';
        }

        if (isTbcDate(date)) {
          return 'TBC';
        }

        if (moment.utc(new Date(date)).date() === 1) {
          return moment.utc(new Date(date)).format('MMM YYYY');
        }
        return moment.utc(new Date(date)).format('DD MMM YYYY');
      } else {
        return 'No Start Set';
      }
    },

    formatEndDate(date) {
      if (date) {
        if (isTbcDate(date)) {
          return 'TBC';
        }

        const endOfMonth = moment.utc(new Date(date)).endOf('month');
        if (moment.utc(new Date(date)).date() === endOfMonth.date()) {
          return moment.utc(new Date(date)).format('MMM YYYY');
        }
        return moment.utc(new Date(date)).format('DD MMM YYYY');
      } else {
        return null;
      }
    },

    formatPeriod(startDate, endDate, joinString = ' - ') {
      if (isDefaultDate(startDate)) {
        return 'Available';
      }

      if (isTbcDate(startDate)) {
        return 'TBC';
      }

      const formatStartDate = this.formatStartDate(startDate);
      const formatEndDate = this.formatEndDate(endDate);

      if (formatStartDate === formatEndDate) {
        return formatStartDate + ' only';
      }

      if (null === formatEndDate) {
        return formatStartDate;
      }

      return formatStartDate + joinString + formatEndDate;
    },

    formatDate(date) {
      if (date) {
        return moment.utc(new Date(date)).format('DD MMM YYYY');
      }
    },

    /**
     * Compares 2 sets of dates to form a suitable date period
     * @param {string} startDates
     * @param {string} endDates
     * @return {
     *  {
     *    preferredStartUsed: boolean,
     *    start,
     *    preferredEndUsed: boolean,
     *    end: (default.methods.playPeriodStart|default.methods.playPeriodEnd|*)
     *  }
     * }
     */
    getSuitablePlayPeriod(startDates, endDates) {
      let start;
      let end;
      let preferredStartUsed;
      let preferredEndUsed;

      preferredStartUsed = true;
      preferredEndUsed = true;

      const startMin = moment.utc(startDates.minimum);
      const startPref = moment.utc(startDates.preferred);
      const endMax = moment.utc(endDates.maximum);
      const endPref = moment.utc(endDates.preferred);

      if (startMin.isAfter(startPref, 'day')) {
        start = startDates.minimum;
        preferredStartUsed = false;
      } else {
        start = startDates.preferred;
      }

      if (null !== endDates.maximum && endMax.isBefore(endPref, 'day')) {
        end = endDates.maximum;
        preferredEndUsed = false;
      } else {
        end = endDates.preferred;
      }

      if (moment.utc(start) > moment.utc(end)) {
        if (start === startDates.minimum && endDates.maximum !== null) {
          end = endDates.maximum;
          preferredEndUsed = false;
        } else if (endDates.maximum === null) {
          // Convert the date to moment, jump to end of month, add 1 day (to bleed into the next month) then return end
          // of that month for the new end date
          end = moment.utc(start).endOf('month').add('1', 'day').endOf('month').format('YYYY-MM-DD');
          preferredEndUsed = false;
        }

        if (end === endDates.maximum) {
          start = startDates.minimum;
          preferredStartUsed = false;
        }
      }

      return {
        start: start,
        preferredStartUsed: preferredStartUsed,
        end: end,
        preferredEndUsed: preferredEndUsed,
      };
    },
  },
};
