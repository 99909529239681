import DataSourceCode from '@/Client/Store/Constants/DataSourceCode';

export default {
  state: {
    airline: null,
    api: null,
    contentServiceProvider: null,
    dataSources: [],
    distributor: null,
    other: null,
    status: '',
  },
  mutations: {
    dataSource_request(state) {
      state.status = 'loading';
    },
    dataSource_success(state, dataSources) {
      state.status = 'success';
      state.dataSources = dataSources;

      state.dataSources.forEach((src) => {
        switch (src.code) {
          case DataSourceCode.AIRLINE:
            state.airline = src;
            break;
          case DataSourceCode.API:
            state.api = src;
            break;
          case DataSourceCode.CONTENTSERVICEPROVIDER:
            state.contentServiceProvider = src;
            break;
          case DataSourceCode.DISTRIBUTOR:
            state.distributor = src;
            break;
          case DataSourceCode.OTHER:
            state.other = src;
            break;
        }
      });
    },
    dataSource_error(state) {
      state.status = 'error';
    },
  },
  actions: {
    setDataSources({commit}, dataSources) {
      commit('dataSource_success', dataSources);
    },
  },
  getters: {
    dataSource_airline: (state) => {
      return state.airline;
    },
    dataSource_api: (state) => {
      return state.api;
    },
    dataSource_contentServiceProvider: (state) => {
      return state.contentServiceProvider;
    },
    dataSource_distributor: (state) => {
      return state.distributor;
    },
    dataSource_other: (state) => {
      return state.other;
    },
    dataSources: (state) => {
      return state.dataSources;
    },
  },
};
