import ContentTypeCode from '@/Client/Store/Constants/ContentTypeCode';

export default {
  state: {
    status: '',
    genres: [],
    movieGenres: [],
    televisionGenres: [],
    seasonGenres: [],
    episodeGenres: [],
    albumGenres: [],
    trackGenres: [],
    shortGenres: [],
    otherVideoItemGenres: [],
    otherAudioGroupGenres: [],
    otherAudioGroupItemGenres: [],
    otherAudioItemGenres: [],
  },
  mutations: {
    genre_request(state) {
      state.status = 'loading';
    },
    movie_genres(state, genres) {
      state.movieGenres = genres;
    },
    television_genres(state, genres) {
      state.televisionGenres = genres;
    },
    season_genres(state, genres) {
      state.seasonGenres = genres;
    },
    episode_genres(state, genres) {
      state.episodeGenres = genres;
    },
    album_genres(state, genres) {
      state.albumGenres = genres;
    },
    track_genres(state, genres) {
      state.trackGenres = genres;
    },
    short_genres(state, genres) {
      state.shortGenres = genres;
    },
    otherVideoItem_genres(state, genres) {
      state.otherVideoItemGenres = genres;
    },
    otherAudioGroup_genres(state, genres) {
      state.otherAudioGroupGenres = genres;
    },
    otherAudioGroupItem_genres(state, genres) {
      state.otherAudioGroupItemGenres = genres;
    },
    otherAudioItem_genres(state, genres) {
      state.otherAudioItemGenres = genres;
    },
    genre_success(state, genres) {
      state.status = 'success';
      state.genres = genres;
    },
    genre_error(state) {
      state.status = 'error';
    },
  },
  actions: {
    setMovieGenres({commit}, genres) {
      commit('movie_genres', genres);
    },
    setTelevisionGenres({commit}, genres) {
      commit('television_genres', genres);
    },
    setSeasonGenres({commit}, genres) {
      commit('season_genres', genres);
    },
    setEpisodeGenres({commit}, genres) {
      commit('episode_genres', genres);
    },
    setAlbumGenres({commit}, genres) {
      commit('album_genres', genres);
    },
    setTrackGenres({commit}, genres) {
      commit('track_genres', genres);
    },
    setShortGenres({commit}, genres) {
      commit('short_genres', genres);
    },
    setOtherVideoItemGenres({commit}, genres) {
      commit('otherVideoItem_genres', genres);
    },
    setOtherAudioGroupGenres({commit}, genres) {
      commit('otherAudioGroup_genres', genres);
    },
    setOtherAudioGroupItemGenres({commit}, genres) {
      commit('otherAudioGroupItem_genres', genres);
    },
    setOtherAudioItemGenres({commit}, genres) {
      commit('otherAudioItem_genres', genres);
    },
    setGenres({commit}, genres) {
      commit('genre_success', genres);
    },
  },
  getters: {
    availableGenres: (state) => {
      return state.genres;
    },
    genreByContentTypeCode: (state) => {
      return function(contentTypeCode) {
        switch (contentTypeCode) {
          case ContentTypeCode.MOVIE:
            return state.movieGenres;
          case ContentTypeCode.TELEVISION:
            return state.televisionGenres;
          case ContentTypeCode.SEASON:
            return state.seasonGenres;
          case ContentTypeCode.EPISODE:
            return state.episodeGenres;
          case ContentTypeCode.ALBUM:
            return state.albumGenres;
          case ContentTypeCode.TRACK:
            return state.trackGenres;
          case ContentTypeCode.SHORT:
            return state.shortGenres;
          case ContentTypeCode.OTHER_VIDEO_ITEM:
            return state.otherVideoItemGenres;
          case ContentTypeCode.OTHER_AUDIO_GROUP:
            return state.otherAudioGroupGenres;
          case ContentTypeCode.OTHER_AUDIO_GROUP_ITEM:
            return state.otherAudioGroupItemGenres;
          case ContentTypeCode.OTHER_AUDIO_ITEM:
            return state.otherAudioItemGenres;
          default:
            return state.genres;
        }
      };
    },
    movieGenres: (state) => {
      return state.movieGenres;
    },
    televisionGenres: (state) => {
      return state.televisionGenres;
    },
    seasonGenres: (state) => {
      return state.seasonGenres;
    },
    episodeGenres: (state) => {
      return state.episodeGenres;
    },
    shortGenres: (state) => {
      return state.shortGenres;
    },
    albumGenres: (state) => {
      return state.albumGenres;
    },
    trackGenres: (state) => {
      return state.trackGenres;
    },
    otherVideoItemGenres: (state) => {
      return state.otherVideoItemGenres;
    },
    otherAudioGroupGenres: (state) => {
      return state.otherAudioGroupGenres;
    },
    otherAudioGroupItemGenres: (state) => {
      return state.otherAudioGroupItemGenres;
    },
    otherAudioItemGenres: (state) => {
      return state.otherAudioItemGenres;
    },
  },
};
