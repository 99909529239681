import Currency from '@/Client/Store/Constants/Currency';

export default {
  state: {
    status: '',
    currencies: [],
    defaultCurrency: null,
  },
  mutations: {
    currency_request(state) {
      state.status = 'loading';
    },
    currency_success(state, currencies) {
      state.currencies = currencies;

      const defaultCurrency = currencies.filter((currency) => {
        return currency.name === Currency.DEFAULT;
      });

      if (defaultCurrency.length > 0) {
        state.defaultCurrency = defaultCurrency[0];
      } else {
        state.defaultCurrency = currencies[0];
      }

      state.status = 'success';
    },
    currency_error(state) {
      state.status = 'error';
    },
  },
  actions: {
    setCurrencies({commit}, currencies) {
      commit('currency_success', currencies);
    },
  },
  getters: {
    currencies: (state) => {
      return state.currencies;
    },
    defaultCurrency: (state) => {
      return state.defaultCurrency;
    },
  },
};
