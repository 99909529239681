export default {
  state: {
    status: '',
    imageFormat: [],
  },
  mutations: {
    imageFormat_request(state) {
      state.status = 'loading';
    },
    imageFormat_success(state, formats) {
      state.status = 'success';
      state.imageFormats = formats;
    },
    imageFormat_error(state) {
      state.status = 'error';
    },
  },
  actions: {
    setImageFormats({commit}, imageFormats) {
      commit('imageFormat_success', imageFormats);
    },
  },
  getters: {
    imageFormats: (state) => {
      return state.imageFormats;
    },
  },
};
