import Vue from 'vue';

export default {
  state: {
    status: '',
    distributorContacts: [],
  },
  mutations: {
    distributor_contact_request(state) {
      state.status = 'loading';
    },
    distributor_contact_success(state, distributorContacts) {
      state.status = 'success';
      state.distributorContacts = distributorContacts;
    },
    distributor_contact_error(state) {
      state.status = 'error';
    },
  },
  actions: {
    distributorContacts({commit}) {
      if (this.getters.availableDistributorContacts.length <= 0) {
        return new Promise((resolve, reject) => {
          commit('distributor_contact_request');
          Vue.axios.get(
              '/distributor_contacts',
              {params: {'order[contact.firstName]': 'asc'},
              })
              .then((resp) => {
                const distributorContacts = resp.data['hydra:member'];
                commit('distributor_contact_success', distributorContacts);
                resolve(resp);
              })
              .catch((err) => {
                commit('distributor_contact_error');
                reject(err);
              });
        });
      }
    },
  },
  getters: {
    availableDistributorContacts: (state) => {
      return state.distributorContacts;
    },
  },
};
