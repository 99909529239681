// eslint-disable-next-line camelcase
import {required, oneOf, min, email, regex, max, min_value, max_value} from 'vee-validate/dist/rules';
import {extend, localize} from 'vee-validate';
import en from 'vee-validate/dist/locale/en.json';

/**
 * Function to initialise validation rules
 */
function initValidation() {
  extend('required', {
    ...required,
    message: 'This field is required',
  });

  extend('email', {
    ...email,
    message: 'This must be a valid e-mail address',
  });

  extend('oneOf', {
    ...oneOf,
    message: 'This must be a value from the list',
  });

  extend('min', {
    ...min,
    message: 'This value is not long enough',
  });

  extend('between', {
    validate(value, {min, max}) {
      return Number(value) >= min && Number(value) <= max;
    },
    params: ['min', 'max'],
    message: 'This number must be between {min} and {max}',
  });

  extend('before', {
    validate: (value, {target}) => {
      return null !== target ? new Date(value) <= new Date(target) : true;
    },
    params: ['target'],
    message: 'This date is too late',
  });

  extend('after', {
    validate: (value, {target}) => {
      return new Date(value) >= new Date(target);
    },
    params: ['target'],
    message: 'This date is too early',
  });

  extend('regex', {
    ...regex,
    message: 'The input does not match the pattern requirements',
  });

  extend('confirm', {
    params: ['target'],
    validate(value, {target}) {
      return value === target;
    },
    message: 'The new passwords do not match',
  });

  extend('max', {
    ...max,
    message: 'The input has too many characters',
  });

  extend('max_value', {
    // eslint-disable-next-line camelcase
    ...max_value,
    message: 'The value is too high',
  });

  extend('min_value', {
    // eslint-disable-next-line camelcase
    ...min_value,
    message: 'The value is too low',
  });

  /**
   * Special validation rule for validating when there's a value in a typeahead's text input is not empty, warning users
   * that the value in it hasn't been selected yet
   *
   * Usage: rules="`typeahead_input_is_empty|${typeaheadInputValue}`"
   */
  extend('typeahead_input_is_empty', {
    params: ['target'],
    validate(value, {target}) {
      return {
        valid: null === target || '' === target || 'null' === target || !target,
        data: {required: true,},
      };
    },
    message: 'The value has not been selected',
    computesRequired: true,
  });


  localize('en', en);
}

export default initValidation;
