import moment from 'moment';
import Vue from 'vue';
import Currency from '@/Client/Mixin/Currency';

function isDefaultDate(value) {
  return moment.utc(new Date(value)).format('MMM YYYY') === 'Jan 1900';
}

function isTbcDate(value) {
  return moment.utc(new Date(value)).format('YYMMDD') === '991231';
}

/**
 * Initialiases the commonly used Vue filters
 */
function initFilters() {
  Vue.filter('formatDate', function(value) {
    if (value) {
      return moment.utc(new Date(value)).format('DD-MMM-YY');
    }
  });

  Vue.filter('formatDateTime', function(value) {
    if (value) {
      return moment.utc(new Date(value)).format('ddd DD-MMM-YY HH:mm');
    }
  });

  Vue.filter('formatStartDate', function(value) {
    if (value) {
      if (isDefaultDate(value)) {
        return 'Available';
      }

      if (isTbcDate(value)) {
        return 'TBC';
      }

      if (moment.utc(new Date(value)).date() === 1) {
        return moment.utc(new Date(value)).format('MMM YYYY');
      }
      return moment.utc(new Date(value)).format('DD-MMM-YY');
    }
  });

  Vue.filter('formatEndDate', function(value) {
    if (value) {
      if (isTbcDate(value)) {
        return 'TBC';
      }

      const endOfMonth = moment.utc(new Date(value)).endOf('month');
      if (moment.utc(new Date(value)).date() === endOfMonth.date()) {
        return moment.utc(new Date(value)).format('MMM YYYY');
      }
      return moment.utc(new Date(value)).format('DD-MMM-YY');
    }
  });

  Vue.filter('formatPeriod', function(startDate, endDate, joinString = ' - ') {
    if (isDefaultDate(startDate)) {
      return 'Available';
    }

    if (isTbcDate(startDate)) {
      return 'TBC';
    }

    const formatStartDate = Vue.filter('formatStartDate')(startDate);
    const formatEndDate = Vue.filter('formatEndDate')(endDate);

    if (formatStartDate === formatEndDate) {
      return formatStartDate;
    }

    return formatStartDate + joinString + formatEndDate;
  });

  Vue.filter('dynamicFilter', function(value, name, ...args) {
    if (name !== null && name !== undefined) {
      return Vue.filter(name)(value, ...args);
    }

    return value;
  });

  Vue.filter('truncate', function(text, stop, clamp) {
    return text.slice(0, stop) + (stop < text.length ? clamp || '...' : '');
  });

  Vue.filter('trim', function(text) {
    return text.trim();
  });

  Vue.filter('ifeCurrency', function(currencyValueObject, decimals = 0, locale = null) {
    return Currency.methods.getCurrencyOutput(currencyValueObject, decimals, locale);
  });

  Vue.filter('ucwords', function(str) {
    return (str + '').replace(/^([a-z])|\s+([a-z])/g, function($1) {
      return $1.toUpperCase();
    });
  });
}

export default initFilters;
