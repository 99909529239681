<template>
  <div>
    <background-video
      v-if="hasCompanyBackgroundVideo && options.background"
      :background="getCompanyBackgroundVideo"
    />
    <router-view
      v-if="$route.meta.customPageLayout"
    />
    <ife-container
      v-else
      class="p-0"
      fluid
    >
      <authenticated v-if="isLoggedIn" />
      <access-request v-else-if="'requestAccess' === $route.name" />
      <forgot-password v-else-if="'forgotPassword' === $route.name" />
      <reset-password v-else-if="'resetPassword' === $route.name" />
      <company-login v-else-if="isCompanyLogin" />
      <login v-else />
    </ife-container>
  </div>
</template>

<script>
import AccessRequest from '@/Client/Page/AccessRequest.vue';
import Authenticated from '@/Client/Component/Security/Authenticated';
import BackGroundVideo from '@/Client/Component/BackgroundVideo.vue';
import CompanyLogin from '@/Client/Component/Security/CompanyLogin';
import getCompanyLogin from '@/Client/Store/CompanyLogins';
import ForgotPassword from '@/Client/Page/ForgotPassword.vue';
import {InitStore} from '@/Client/Store';
import Login from '@/Client/Component/Security/Login';
import {mapGetters} from 'vuex';
import ResetPassword from '@/Client/Page/ResetPassword.vue';
import UserOptions from '@/Client/Model/UserOptions';
import UserPreferences from '@/Client/Model/UserPreferences';

export default {
  'name': 'App',
  'components': {
    ResetPassword,
    ForgotPassword,
    AccessRequest,
    'authenticated': Authenticated,
    'background-video': BackGroundVideo,
    'company-login': CompanyLogin,
    'login': Login,
  },
  data() {
    const companyLoginRoute = 'companyLogin';

    return {
      authenticated: null,
      companyLoginRoute: companyLoginRoute,
      documentBodyClassName: 'sky-bg',
      loading: true,
      options: UserOptions,
      permittedRoutes: [
        companyLoginRoute,
        'resetPassword',
      ],
    };
  },
  'computed': {
    ...mapGetters(['isLoggedIn']),
    backgroundPreference() {
      return UserPreferences.getBackgroundPreference() ?? true;
    },
    companyLogin() {
      if (this.$route.name === 'login') {
        UserPreferences.removeCompanyLogin();
      }

      return UserPreferences.getCompanyLogin();
    },
    getCompanyBackgroundVideo() {
      if (this.hasCompanyBackgroundVideo) {
        return require(
            './assets/img/bg/' + this.companyLogin.backgroundVideo,
        );
      }

      return null;
    },
    hasCompanyBackgroundVideo() {
      if (this.companyLogin) {
        return this.companyLogin.hasOwnProperty('backgroundVideo');
      }

      return false;
    },
    isCompanyLogin() {
      return this.companyLoginRoute === this.$route.name ||
          ('home' === this.$route.name && !this.isLoggedIn && null !== this.subDomainCompanyLogin);
    },
    isPermittedRoute() {
      if (this.permittedRoutes.includes(this.$route.name)) {
        return true;
      }

      const pathname = window.location.pathname;

      return '/reset-password' === pathname;
    },
    subDomainCompanyLogin() {
      return getCompanyLogin(this.getSubdomain());
    },
  },
  'watch': {
    'options.background': {
      handler(value) {
        if (value) {
          document.body.className = this.documentBodyClassName;
        } else {
          document.body.className = '';
        }
      },
    },
    '$store.state.user.token'(token) {
      if (token) {
        InitStore.init(this.axios, this.$store);
      }
    },
  },
  beforeMount() {
    if (!this.$store.getters.isLoggedIn && !this.$route.meta.noAuth) {
      this.$store.commit('resetState');
      this.$store.dispatch('logout');
    }
  },
  beforeCreate() {
    if (this.$store.getters.isLoggedIn) {
      InitStore.init(this.axios, this.$store);
    }
  },
  created() {
    this.setCompanyLogin();
    this.setStyling();
  },
  'methods': {
    setCompanyLogin() {
      if (this.$store.getters.isLoggedIn) {
        return;
      }

      const routeCompanyLogin = getCompanyLogin(this.$route.params.company);

      if (this.subDomainCompanyLogin) {
        UserPreferences.setCompanyLogin(this.subDomainCompanyLogin);
      } else {
        if (routeCompanyLogin) {
          UserPreferences.setCompanyLogin(routeCompanyLogin);
        }
      }

      if (!this.subDomainCompanyLogin && !routeCompanyLogin) {
        UserPreferences.removeCompanyLogin();
      }

      if (this.subDomainCompanyLogin && !routeCompanyLogin && !this.isPermittedRoute) {
        this.$router.push({name: this.companyLoginRoute});
      }
    },
    setStyling() {
      if (this.companyLogin) {
        if (this.companyLogin.cssClass) {
          this.documentBodyClassName = this.companyLogin.cssClass;
        }
        if ('undefined' !== typeof this.companyLogin.favicon) {
          this.setFavicon(require('./assets/img/favicon/' + this.companyLogin.favicon));
        }
      }

      if (this.backgroundPreference) {
        document.body.className = this.documentBodyClassName;
      }

      UserOptions.background = this.backgroundPreference;
    },
  },
};
</script>

<style scoped>

</style>
