export default {
  methods: {
    navigateTo(link, newTab = false, focus = false, newWindow = false) {
      const routerLink = {...link.link};
      if ('function' === typeof link.params) {
        routerLink.params = link.params(this.$store.getters);
      }

      if (newTab) {
        const routeData = this.$router.resolve(routerLink);
        const newTab = window.open(routeData.href, '_blank');

        if (focus) {
          newTab.focus();
        }

        return;
      }

      if (newWindow) {
        const routeData = this.$router.resolve(routerLink);
        window.open(
          routeData.href,
          '_blank',
          'location=1,menubar=1,scrollbars=1,status=1,titlebar=1,toolbar=1',
        );
        return;
      }

      this.$router.push(routerLink);
    },
  },
};
