<template>
  <b-modal
    :id="id"
    header-bg-variant="dark"
    header-text-variant="white"
    scrollable
    :body-class="bodyClass"
    :cancel-disabled="cancelDisabled"
    :cancel-title="cancelTitle"
    :centered="centered"
    :content-class="contentClass"
    :hide-footer="hideFooter"
    :hide-header="hideHeader"
    :lazy="lazy"
    :ok-title="okTitle"
    :ok-only="okOnly"
    :size="size"
    :title="title"
    :title-class="titleClass"
    :ok-disabled="okDisabled"
    @ok="ok"
    @close="close"
    @hidden="hidden"
  >
    <template
      v-if="$slots.title"
      #modal-title
    >
      <slot name="title" />
    </template>
    <slot />
  </b-modal>
</template>

<script>
export default {
  name: 'IFEModal',
  props: {
    okDisabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
    bodyClass: {
      type: String,
      default: '',
    },
    cancelDisabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
    cancelTitle: {
      type: String,
      default() {
        return 'Cancel';
      },
    },
    centered: {
      type: Boolean,
      default: false,
    },
    contentClass: {
      type: String,
      default: '',
    },
    hideFooter: {
      type: Boolean,
      default: false,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      required: true,
    },
    lazy: {
      type: Boolean,
      default: false,
    },
    okTitle: {
      type: String,
      default() {
        return 'OK';
      },
    },
    okOnly: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'xl',
    },
    title: {
      type: String,
      required: true,
    },
    titleClass: {
      type: [String, Array],
      default: '',
    },
  },
  methods: {
    close(e) {
      this.$emit('close', e);
    },
    hidden(e) {
      this.$emit('hidden', e);
    },
    ok(e) {
      this.$emit('ok', e);
    },
  },
};
</script>

<style scoped>

</style>
