const AIRLINE = 'AIR';
const API = 'API';
const CONTENTSERVICEPROVIDER = 'CSP';
const DISTRIBUTOR = 'DST';
const OTHER = 'OTH';

/**
 * Class used to make content type constants globally available
 */
export default class DataSourceCode {
  /**
   * Static getter for the Airline data source code
   * @return {string}
   * @constructor
   */
  static get AIRLINE() {
    return AIRLINE;
  }

  /**
   * Static getter for the API data source code
   * @return {string}
   * @constructor
   */
  static get API() {
    return API;
  }

  /**
   * Static getter for the Content Service Provider data source code
   * @return {string}
   * @constructor
   */
  static get CONTENTSERVICEPROVIDER() {
    return CONTENTSERVICEPROVIDER;
  }

  /**
   * Static getter for the Distributor data source code
   * @return {string}
   * @constructor
   */
  static get DISTRIBUTOR() {
    return DISTRIBUTOR;
  }

  /**
   * Static getter for the Other data source code
   * @return {string}
   * @constructor
   */
  static get OTHER() {
    return OTHER;
  }
};
