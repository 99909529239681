import Vue from 'vue';

export default {
  state: {
    status: '',
    airlineContacts: [],
  },
  mutations: {
    airline_contact_request(state) {
      state.status = 'loading';
    },
    airline_contact_success(state, airlineContacts) {
      state.status = 'success';
      state.airlineContacts = airlineContacts;
    },
    airline_contact_error(state) {
      state.status = 'error';
    },
  },
  actions: {
    airlineContacts({commit}) {
      if (this.getters.availableAirlineContacts.length <= 0) {
        return new Promise((resolve, reject) => {
          commit('airline_contact_request');
          Vue.axios.get(
              `/airline_contacts`,
              {params: {'order[contact.firstName]': 'asc'},
              })
              .then((resp) => {
                const airlineContacts = resp.data['hydra:member'];
                commit('airline_contact_success', airlineContacts);
                resolve(resp);
              })
              .catch((err) => {
                commit('airline_contact_error');
                reject(err);
              });
        });
      }
    },
  },
  getters: {
    availableAirlineContacts: (state) => {
      return state.airlineContacts;
    },
  },
};
