export default {
  state: {
    status: '',
    stateSentiments: [],
  },
  mutations: {
    sentiment_request(state) {
      state.status = 'loading';
    },
    sentiment_success(state, stateSentiments) {
      state.status = 'success';
      state.stateSentiments = stateSentiments;
    },
    sentiment_error(state) {
      state.status = 'error';
    },
  },
  actions: {
    setStateSentiments({commit}, stateSentiments) {
      commit('sentiment_success', stateSentiments);
    },
  },
  getters: {
    availableStateSentiments: (state) => {
      return state.stateSentiments;
    },
  },
};
