<template>
  <div>
    <b-badge
      v-for="(item, index) in itemsToDisplay"
      :key="index"
      v-b-tooltip.hover.top="tooltip && resolveDeepProperty(tooltip, item) ? resolveDeepProperty(tooltip, item) : null"
      :variant="variant"
      class="mr-1"
    >
      <span v-if="rawText">{{ item }}</span>
      <span v-else-if="node">{{ item.node[itemKey] }}</span>
      <span v-else-if="flatArray">{{ item }}</span>
      <span v-else-if="resolveDeepProperty(itemKey, item)">
        {{ resolveDeepProperty(itemKey, item) }}
      </span>
    </b-badge>
    <b-badge
      v-if="additionalItems.length > 0"
      v-b-tooltip.hover.top="getAdditionalItems"
      :variant="variant"
    >
      <span>...</span>
    </b-badge>
  </div>
</template>

<script>
export default {
  name: 'BadgeCollection',
  props: {
    flatArray: {
      type: Boolean,
      default() {
        return false;
      },
    },
    items: {
      type: Array,
      required: true,
    },
    itemKey: {
      type: String,
      default() {
        return 'name';
      },
    },
    maxVisible: {
      type: Number,
      default() {
        return null;
      },
    },
    node: {
      type: Boolean,
      default() {
        return false;
      },
    },
    rawText: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: null,
    },
    variant: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      additionalItems: [],
      itemsToDisplay: [],
    };
  },
  mounted() {
    if (null !== this.maxVisible) {
      this.itemsToDisplay = this.items.slice(0, this.maxVisible);
      this.additionalItems = this.items.slice(this.maxVisible, this.items.length);
      return;
    }

    this.itemsToDisplay = this.items;
  },
  methods: {
    getAdditionalItems() {
      const additional = [];
      this.additionalItems.forEach((item) => {
        if (this.rawText) {
          additional.push(item);
        } else if (this.node) {
          additional.push(item.node[this.itemKey]);
        } else {
          additional.push(this.resolveDeepProperty(this.itemKey, item));
        }
      });

      return additional.join(', ');
    },
  },
};
</script>

<style scoped>

</style>
