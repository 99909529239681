import EntityTypeCode from '@/Client/Store/Constants/EntityTypeCode';

export default {
  state: {
    status: '',
    entityTypes: [],
    contentEntityType: null,
    licenseEntityType: null,
    invoiceEntityType: null,
    orderEntityType: null,
    availabilityEntityType: null,
    materialOrderEntityType: null,
    materialOrderItemEntityType: null,
    selectionEntityType: null,
    selectionGroupEntityType: null,
  },
  mutations: {
    entityType_request(state) {
      state.status = 'loading';
    },
    entityType_full_list(state, entityTypes) {
      state.entityTypes = entityTypes;
    },
    entityType_content_id(state, contentId) {
      state.contentEntityType = contentId;
    },
    entityType_license_id(state, licenseId) {
      state.licenseEntityType = licenseId;
    },
    entityType_invoice_id(state, invoiceId) {
      state.invoiceEntityType = invoiceId;
    },
    entityType_order_id(state, orderId) {
      state.orderEntityType = orderId;
    },
    entityType_availability_id(state, availabilityId) {
      state.availabilityEntityType = availabilityId;
    },
    entityType_material_order_id(state, materialOrderId) {
      state.materialOrderEntityType = materialOrderId;
    },
    entityType_material_order_item_id(state, materialOrderItemId) {
      state.materialOrderItemEntityType = materialOrderItemId;
    },
    entityType_selection_id(state, selectionId) {
      state.selectionEntityType = selectionId;
    },
    entityType_selection_group_id(state, selectionGroupId) {
      state.selectionGroupEntityType = selectionGroupId;
    },
    entityType_success(state) {
      state.status = 'success';
    },
    entityType_error(state) {
      state.status = 'error';
    },
  },
  actions: {
    setEntityTypes({commit}, entityTypes) {
      const types = [];
      entityTypes.forEach((type) => {
        switch (type.code) {
          case EntityTypeCode.CONTENT:
            commit('entityType_content_id', type.id);
            break;
          case EntityTypeCode.LICENSE:
            commit('entityType_license_id', type.id);
            break;
          case EntityTypeCode.INVOICE:
            commit('entityType_invoice_id', type.id);
            break;
          case EntityTypeCode.LICENSE_ORDER:
            commit('entityType_order_id', type.id);
            break;
          case EntityTypeCode.AVAILABILITY:
            commit('entityType_availability_id', type.id);
            break;
          case EntityTypeCode.MATERIAL_ORDER:
            commit('entityType_material_order_id', type.id);
            break;
          case EntityTypeCode.MATERIAL_ORDER_ITEM:
            commit('entityType_material_order_item_id', type.id);
            break;
          case EntityTypeCode.SELECTION:
            commit('entityType_selection_id', type.id);
            break;
          case EntityTypeCode.SELECTION_GROUP:
            commit('entityType_selection_group_id', type.id);
            break;
        }

        types.push({
          id: type.id,
          name: type.name,
          code: type.code,
        });
      });

      commit('entityType_full_list', types);
      commit('entityType_success');
    },
  },
  getters: {
    availableEntityTypes: (state) => {
      return state.entityTypes;
    },
    contentEntityType: (state) => {
      return state.contentEntityType;
    },
    licenseEntityType: (state) => {
      return state.licenseEntityType;
    },
    invoiceEntityType: (state) => {
      return state.invoiceEntityType;
    },
    orderEntityType: (state) => {
      return state.orderEntityType;
    },
    availabilityEntityType: (state) => {
      return state.availabilityEntityType;
    },
    materialOrderEntityType: (state) => {
      return state.materialOrderEntityType;
    },
    materialOrderItemEntityType: (state) => {
      return state.materialOrderItemEntityType;
    },
    selectionEntityType: (state) => {
      return state.selectionEntityType;
    },
    selectionGroupEntityType: (state) => {
      return state.selectionGroupEntityType;
    },
  },
};
