export default {
  state: {
    status: '',
    labs: [],
  },
  mutations: {
    lab_request(state) {
      state.status = 'loading';
    },
    lab_success(state, labs) {
      state.status = 'success';
      state.labs = labs;
    },
    lab_error(state) {
      state.status = 'error';
    },
  },
  actions: {
    setLabs({commit}, labs) {
      commit('lab_success', labs);
    },
  },
  getters: {
    availableLabs: (state) => {
      return state.labs;
    },
  },
};
