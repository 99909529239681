import Axios from 'axios';
import store from '@/Client/Store';

const coreApiInstance = Axios.create({
  baseURL: process.env.CORE_API_URL + '/api',
  headers: {
    'Content-Type': 'application/json',
  },
});

const authInstance = Axios.create({
  baseURL: process.env.CORE_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

const externalApiInstance = Axios.create({
  baseURL: 'https://' + process.env.IMDB_ALT_HOST + '/',
  headers: {
    'X-RapidAPI-Host': process.env.IMDB_ALT_HOST,
    'X-RapidAPI-Key': process.env.IMDB_ALT_KEY,
    'Content-Type': 'application/json',
  },
});

const coreApiToken = localStorage.getItem('token');

if (coreApiToken) {
  coreApiInstance.defaults.headers.common['Authorization'] = coreApiToken;
}

Axios.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if (error.response.status === 401) {
    store.dispatch('logout').then((response) => {
      window.location.href = '/login';
    }).catch((error) => {
      console.error('Axios interceptors have been incorrectly configured:', error.message);
    });

    return error;
  }
});

export default {
  coreApiInstance,
  authInstance,
  externalApiInstance,
};
