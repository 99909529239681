import {apolloClient} from '@/vue-apollo';
import ContentTypeCode from '@/Client/Store/Constants/ContentTypeCode';

export default {
  state: {
    status: '',
    versionTypes: [],
    theatricalVersionType: null,
    standardVersionType: null,
    movieVersionTypes: [],
    episodeVersionTypes: [],
    shortVersionTypes: [],
  },
  mutations: {
    versionType_request(state) {
      state.status = 'loading';
    },
    versionType_success(state, versionTypes) {
      state.status = 'success';
      state.versionTypes = versionTypes;
    },
    movieVersionTypes(state, versionTypes) {
      state.movieVersionTypes = versionTypes;
    },
    episodeVersionTypes(state, versionTypes) {
      state.episodeVersionTypes = versionTypes;
    },
    shortVersionTypes(state, versionTypes) {
      state.shortVersionTypes = versionTypes;
    },
    theatricalVersionType(state, versionTypes) {
      state.theatricalVersionType = versionTypes.filter((type) => {
        return 'TH' === type.code;
      })[0];
    },
    standardVersionType(state, versionTypes) {
      state.standardVersionType = versionTypes.filter((type) => {
        return 'SE' === type.code;
      })[0];
    },
    versionType_error(state) {
      state.status = 'error';
    },
  },
  actions: {
    versionTypes({commit}) {
      commit('versionType_request');
      apolloClient.query({
        query: require('@/graphql/Query/Collection/VersionTypes.graphql'),
      }).then((response) => {
        commit('theatricalVersionType', response.data.versionTypes);
        commit('standardVersionType', response.data.versionTypes);
        commit('versionType_success', response.data.versionTypes);

        const movieVersionTypes = response.data.versionTypes.filter((versionType) => {
          return versionType.contentTypes.map((contentType) => {
            return contentType.code;
          }).includes(ContentTypeCode.MOVIE);
        });

        const episodeVersionTypes = response.data.versionTypes.filter((versionType) => {
          return versionType.contentTypes.map((contentType) => {
            return contentType.code;
          }).includes(ContentTypeCode.EPISODE);
        });

        const shortVersionTypes = response.data.versionTypes.filter((versionType) => {
          return versionType.contentTypes.map((contentType) => {
            return contentType.code;
          }).includes(ContentTypeCode.SHORT);
        });

        commit('episodeVersionTypes', episodeVersionTypes);
        commit('movieVersionTypes', movieVersionTypes);
        commit('shortVersionTypes', shortVersionTypes);
      }).catch((error) => {
        commit('versionType_error');
      });
    },
  },
  getters: {
    availableVersions: (state) => {
      return state.versionTypes;
    },
    theatricalVersion: (state) => {
      return state.theatricalVersionType;
    },
    standardVersion: (state) => {
      return state.standardVersionType;
    },
    movieVersionTypes: (state) => {
      return state.movieVersionTypes;
    },
    episodeVersionTypes: (state) => {
      return state.episodeVersionTypes;
    },
    shortVersionTypes: (state) => {
      return state.shortVersionTypes;
    },
  },

};
