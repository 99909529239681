import Sentiment from '@/Client/Class/Module/State/Sentiment';

export default {
  methods: {
    getSentimentByStateId(stateId, availableStateSentiments) {
      let sentiment = 'NEU';
      const stateSentiment = availableStateSentiments.filter((stateSentiment) => {
        return stateSentiment.state.id === stateId;
      });

      if (stateSentiment.length > 0) {
        sentiment = stateSentiment[0].sentiment.code;
      }

      return Sentiment.getVariant(sentiment);
    },
  },
};
