const ITEM = 'ITM';
const GROUP = 'GRP';
const PLAYLIST = 'PL';

/**
 * Class used to make selection type constants globally available
 */
export default class SelectionTypeCode {
  /**
   * Static getter for the Item selection type;
   * @return {string}
   * @constructor
   */
  static get ITEM() {
    return ITEM;
  }

  /**
   * Static getter for the Group selection type
   * @return {string}
   * @constructor
   */
  static get GROUP() {
    return GROUP;
  }

  /**
   * Static getter for the Playlist selection type
   * @return {string}
   * @constructor
   */
  static get PLAYLIST() {
    return PLAYLIST;
  }
};
