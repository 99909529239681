/**
 * Checks to find a valid company login matching the passed in company
 * @param {object} company
 * @return {object}
 */
export default function getCompanyLogin(company) {
  const login = logins.find((c) => c.name === company);

  if (!login) {
    return null;
  }

  if (!Object.hasOwn(login, 'enabled') || !login.enabled) {
    return null
  }

  return login;
}

const logins = [
  {
    name: 'alpha',
    logo: 'alpha-logo.png',
    cssClass: 'alpha',
    windowTitle: 'Alpha Pictures | IFE Data',
    route: 'distributorLanding',
    id: 'e8997d13-5cbe-4431-af68-70e6938b30d6',
    requestAccountEnabled: true,
    enabled: false,
  },
  {
    name: 'atallorder',
    logo: 'ato-logo.png',
    cssClass: 'ato',
    favicon: 'ato-favicon.png',
    windowTitle: 'A Tall Order | IFE Data',
    route: 'distributorLanding',
    id: '480f52e1-e546-4670-9768-2fb2ef1fbd82',
    requestAccountEnabled: true,
    hideRequestAccessFields: ['airlineClient'],
    enabled: true,
  },
  {
    name: 'captive',
    logo: 'captive-logo.png',
    cssClass: 'captive',
    windowTitle: 'Captive | IFE Data',
    route: 'distributorLanding',
    id: '9fe49bd3-903d-4c4d-b4e5-8001ee19a011',
    requestAccountEnabled: true,
    enabled: false,
  },
  {
    name: 'contentino',
    logo: 'ctino-logo.png',
    cssClass: 'ctino',
    windowTitle: 'Shemaroo Contentino | IFE Data',
    route: 'distributorLanding',
    id: 'd71871f4-a68c-44d0-9a18-3634951193aa',
    requestAccountEnabled: true,
    enabled: false,
  },
  {
    name: 'encore',
    cssClass: 'enc',
    logo: 'enc-logo.png',
    windowTitle: 'Encore | IFE Data',
    route: 'distributorLanding',
    id: 'b9347ac5-8841-42a8-98ac-7b749862c0fc',
    favicon: 'enc-favicon.png',
    requestAccountEnabled: true,
    enabled: false,
  },
  {
    name: 'france24',
    cssClass: 'f24',
    logo: 'f24-logo.png',
    windowTitle: 'France24 | IFE Data',
    route: 'distributorLanding',
    id: '64fde8ef-737a-449a-a2e7-bd88955945c5',
    favicon: 'f24-favicon.ico',
    requestAccountEnabled: true,
    enabled: false,
  },
  {
    name: 'horizon',
    cssClass: 'hzn',
    logo: 'hzn-logo.png',
    windowTitle: 'Horizon | IFE Data',
    route: 'distributorLanding',
    id: '92acbd0b-03d2-42f3-86c6-ace35a5e281e',
    favicon: 'hzn-favicon.png',
    requestAccountEnabled: true,
    enabled: false,
  },
  {
    name: 'inflightflix',
    cssClass: 'iflix',
    logo: 'iflix-logo.png',
    windowTitle: 'InflightFlix | IFE Data',
    route: 'distributorLanding',
    id: '631769cb-bdb1-4e1a-ac8e-876fb784e72c',
    requestAccountEnabled: true,
    enabled: true,
  },
  {
    name: 'lionsgate',
    cssClass: 'lgate',
    logo: 'lgate-logo.png',
    windowTitle: 'Lionsgate | IFE Data',
    backgroundVideo: 'lgate.mp4',
    route: 'distributorLanding',
    id: '42bc5cee-b9ee-4ae0-b874-53ecced7e441',
    favicon: 'lgate-favicon.png',
    requestAccountEnabled: false,
    enabled: true,
  },
  {
    name: 'pictureworks',
    cssClass: 'pworks',
    logo: 'pworks-logo.png',
    windowTitle: 'Pictureworks | IFE Data',
    route: 'distributorLanding',
    id: '0d95a311-94ba-4c48-b051-7f89a4ca866d',
    requestAccountEnabled: true,
    enabled: false,
  },
  {
    name: 'ricochet',
    cssClass: 'rdm',
    logo: 'rdm-logo.png',
    windowTitle: 'Ricochet Digital Media | IFE Data',
    route: 'distributorLanding',
    id: 'e66dff3c-f534-40f8-8ade-1cd2c16a6ed9',
    favicon: 'rdm-favicon.png',
    requestAccountEnabled: true,
    enabled: false,
  },
  {
    name: 'skeye',
    cssClass: 'skeye',
    logo: 'skeye-logo.png',
    windowTitle: 'Skeye | IFE Data',
    route: 'distributorLanding',
    id: '148883e9-108b-4a43-b3f8-83085e53a72e',
    favicon: 'skeye-favicon.png',
    requestAccountEnabled: true,
    enabled: false,
  },
  {
    name: 'tsi',
    cssClass: 'tsi',
    logo: 'tsi-footer.png',
    windowTitle: 'Terry Steiner International | IFE Data',
    route: 'distributorLanding',
    id: '4f35c5bd-0113-492e-873c-a2f538946ff4',
    favicon: 'tsi-favicon.ico',
    requestAccountEnabled: true,
    enabled: false,
  },
];
