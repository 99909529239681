const DEFAULT = 'USD';

/**
 * Class used for global retrieval of default Currency name
 */
export default class Currency {
  /**
   * Static getter for default Currency
   * @return {string}
   */
  static get DEFAULT() {
    return DEFAULT;
  }
}
