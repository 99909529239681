<template>
  <ife-container
    id="main"
    :class="cssClass"
    fluid
  >
    <validation-observer ref="resetPasswordFormObserver">
      <showcase-company-logo />
      <ife-row align-h="center">
        <ife-col
          cols="12"
          md="7"
          lg="5"
          class="mt-4"
        >
          <ife-card
            v-if="complete"
            title="Thank you"
            :class="`ife-card ${cssClass}`"
          >
            <p>
              You have successfully reset your password.
            </p>

            <p>
              <b-btn
                variant="primary"
                @click="goBackToLogin()"
              >
                Go to Login
              </b-btn>
            </p>
          </ife-card>
          <b-overlay
            v-else
            :show="loading"
          >
            <ife-card
              title="Reset your password"
              :class="`ife-card ${cssClass}`"
            >
              <b-alert
                v-if="error"
                variant="danger"
                show
              >
                {{ error }}
              </b-alert>
              <ife-form @submit="submit">
                <validation-provider
                  v-slot="v"
                  vid="password"
                  name="password"
                  :rules="
                    {
                      required: true,
                      regex: passwordRegex
                    }"
                >
                  <b-form-group>
                    <b-form-input
                      v-model="password"
                      :state="getValidationState(v)"
                      placeholder="New Password"
                      type="password"
                    />
                    <b-form-invalid-feedback>
                      {{ v.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <validation-provider
                  v-slot="v"
                  rules="required|confirm:@password"
                >
                  <b-form-group>
                    <b-form-input
                      v-model="confirmPassword"
                      :state="getValidationState(v)"
                      placeholder="Confirm New Password"
                      type="password"
                    />
                    <b-form-invalid-feedback>
                      {{ v.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <b-btn
                  size="sm"
                  variant="primary"
                  type="submit"
                >
                  Reset
                </b-btn>
                <b-btn
                  size="sm"
                  variant="secondary"
                  @click="goBackToLogin()"
                >
                  Cancel
                </b-btn>
              </ife-form>
            </ife-card>
          </b-overlay>
        </ife-col>
      </ife-row>
    </validation-observer>
  </ife-container>
</template>

<script>
import Regex from '@/Client/Store/Regex';
import ShowcaseCompanyLogo from '@/Client/Page/ShowcaseCompanyLogo.vue';
import UserPreferences from '@/Client/Model/UserPreferences';

export default {
  name: 'ResetPassword',
  components: {
    'showcase-company-logo': ShowcaseCompanyLogo,
  },
  data() {
    return {
      complete: false,
      confirmPassword: null,
      error: null,
      loading: false,
      password: null,
      passwordRegex: Regex.password,
      token: null,
    };
  },
  computed: {
    companyLogin() {
      return UserPreferences.getCompanyLogin();
    },
    cssClass() {
      return this.hasCompanyLogin ? this.companyLogin.cssClass : '';
    },
    hasCompanyLogin() {
      return null !== this.companyLogin;
    },
  },
  mounted() {
    if (!this.$route.query.token) {
      this.$router.push({name: 'login'});
    }

    this.token = this.$route.query.token;
  },
  methods: {
    goBackToLogin() {
      const name = this.hasCompanyLogin ? 'companyLogin' : 'login';
      const params = this.hasCompanyLogin ? {company: this.companyLogin.name} : {};

      this.$router.push({name: name, params: params});
    },
    submit() {
      const isValid = this.$refs.resetPasswordFormObserver.validate();
      if (!isValid) {
        return;
      }

      this.loading = true;
      this.error = null;

      this.axios.post(
          `${process.env.CORE_API_URL}/reset-password/${this.token}`,
          {
            password: this.password,
            verify: this.confirmPassword,
            token: this.token,
          },
      )
          .then(() => {
            this.complete = true;
          })
          .catch((error) => {
            if (error.response && error.response.data.error) {
              this.error = error.response.data.error;
              return;
            }

            this.error = 'Something went wrong, please try again.';
          })
          .finally(() => {
            this.loading = false;
          });
    },
  },
};
</script>

<style scoped>

</style>
