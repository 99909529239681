export default {
  state: {
    status: '',
    audioChannels: [],
  },
  mutations: {
    audioChannel_request(state) {
      state.status = 'loading';
    },
    audioChannel_success(state, channels) {
      state.status = 'success';
      state.audioChannels = channels;
    },
    audioChannel_error(state) {
      state.status = 'error';
    },
  },
  actions: {
    setAudioChannels({commit}, audioChannels) {
      commit('audioChannel_success', audioChannels);
    },
  },
  getters: {
    audioChannels: (state) => {
      return state.audioChannels;
    },
  },
};
