const variantMap = [
  {
    code: 'POSD',
    variant: 'successd',
  },
  {
    code: 'POS',
    variant: 'success',
  },
  {
    code: 'POSL',
    variant: 'successl',
  },
  {
    code: 'NEUD',
    variant: 'secondary',
  },
  {
    code: 'NEU',
    variant: 'secondary',
  },
  {
    code: 'NEUL',
    variant: 'secondary',
  },
  {
    code: 'WARND',
    variant: 'warningd',
  },
  {
    code: 'WARN',
    variant: 'warning',
  },
  {
    code: 'WARNL',
    variant: 'warningl',
  },
  {
    code: 'NEGD',
    variant: 'dangerd',
  },
  {
    code: 'NEG',
    variant: 'danger',
  },
  {
    code: 'NEGL',
    variant: 'dangerl',
  },
  {
    code: 'INFD',
    variant: 'infod',
  },
  {
    code: 'INF',
    variant: 'info',
  },
  {
    code: 'INFL',
    variant: 'infol',
  },
];

const fallbackVariant = 'info';

/**
 * Class to house the static getter used to retrieve a variant based on a sentiment
 */
export default class Sentiment {
  /**
   * Returns the variant for the given sentiment code
   * @param {string} code
   * @return {string}
   */
  static getVariant(code) {
    const mapEntry = variantMap.filter((sentiment) => {
      return sentiment.code === code;
    });

    if (mapEntry.length !== 0) {
      return mapEntry[0].variant;
    }

    return fallbackVariant;
  }
}
