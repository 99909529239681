export default {
  state: {
    status: '',
    orderAsContentTypes: [],
  },
  mutations: {
    orderAsContentTypes_request(state) {
      state.status = 'loading';
    },
    orderAsContentTypes_success(state, orderAsContentTypes) {
      state.status = 'success';
      state.orderAsContentTypes = orderAsContentTypes;
    },
    orderAsContentTypes_error(state) {
      state.status = 'error';
    },
  },
  actions: {
    setOrderAsContentTypes({commit}, orderAsContentTypes) {
      commit('orderAsContentTypes_success', orderAsContentTypes);
    },
  },
  getters: {
    orderAsContentTypes: (state) => {
      return state.orderAsContentTypes;
    },
  },
};
