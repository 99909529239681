export default {
  state: {
    status: '',
    countries: [],
  },
  mutations: {
    country_request(state) {
      state.status = 'loading';
    },
    country_success(state, countries) {
      state.status = 'success';
      state.countries = countries;
    },
    country_error(state) {
      state.status = 'error';
    },
  },
  actions: {
    setCountries({commit}, countries) {
      const filtered = [];
      for (let i = 0; i < countries.length; i++) {
        if (countries[i]['country']) {
          filtered.push(countries[i]);
        }
      }
      commit('country_success', filtered);
    },
  },
  getters: {
    _availableCountries: (state) => {
      return state.countries;
    },
  },
};
