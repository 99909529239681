export default {
  state: {
    status: '',
    aspectRatios: [],
  },
  mutations: {
    aspect_request(state) {
      state.status = 'loading';
    },
    aspect_success(state, aspectRatios) {
      state.status = 'success';
      state.aspectRatios = aspectRatios;
    },
    aspect_error(state) {
      state.status = 'error';
    },
  },
  actions: {
    setAspectRatios({commit}, aspectRatios) {
      commit('aspect_success', aspectRatios);
    },
  },
  getters: {
    aspectRatios: (state) => {
      return state.aspectRatios;
    },
  },
};
