export default {
  state: {
    status: '',
    fileExtensions: [],
  },
  mutations: {
    fileExtension_request(state) {
      state.status = 'loading';
    },
    fileExtension_success(state, fileExtensions) {
      state.status = 'success';
      state.fileExtensions = fileExtensions;
    },
    fileExtension_error(state) {
      state.status = 'error';
    },
  },
  actions: {
    setFileExtensions({commit}, fileExtensions) {
      commit('fileExtension_success', fileExtensions);
    },
  },
  getters: {
    fileExtensions: (state) => {
      return state.fileExtensions;
    },
  },
};
