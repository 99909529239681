export default {
  state: {
    status: '',
    userCompanyConfigOptions: [],
  },
  mutations: {
    userCompanyConfigOptions_request(state) {
      state.status = 'loading';
    },
    userCompanyConfigOptions_success(state, userCompanyConfigOptions) {
      state.status = 'success';
      state.userCompanyConfigOptions = userCompanyConfigOptions;
    },
    userCompanyConfigOptions_error(state) {
      state.status = 'error';
    },
  },
  actions: {
    setUserCompanyConfigOptions({commit}, userCompanyConfigOptions) {
      commit('userCompanyConfigOptions_success', userCompanyConfigOptions);
    },
  },
  getters: {
    userCompanyConfigOptions: (state) => {
      return state.userCompanyConfigOptions;
    },
  },
};
