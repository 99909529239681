export default {
  methods: {
    coverUrlToFullPath(coverUrl) {
      return `${process.env.CORE_API_URL}${coverUrl}`;
    },
    // Converts the thumbnail uri to a URL if necessary
    // The API falls back to the full resolution cover file if there is no thumbnail available
    getContentCoverThumbnailUrl(contentCover) {
      if (!contentCover.hasOwnProperty('thumbnailUrl')) {
        return null;
      }

      if (contentCover.thumbnailUrl.includes('http')) {
        return contentCover.thumbnailUrl;
      }

      return this.coverUrlToFullPath(contentCover.thumbnailUrl);
    },
  },
};
