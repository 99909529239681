<template>
  <div>
    <div
      v-if="$apollo.loading"
      class="text-center"
    >
      <b-spinner /><br>Loading Contact Information
    </div>
    <b-card v-else>
      <ife-row>
        <ife-col>
          Name:
        </ife-col>
        <ife-col>
          {{ user.name }}
        </ife-col>
      </ife-row>

      <ife-row>
        <ife-col>
          E-mail Address
        </ife-col>
        <ife-col>
          {{ user.email }}
        </ife-col>
      </ife-row>

      <div
        v-if="person"
        class="mt-3"
      >
        <ife-row>
          <ife-col>
            Telephone Number:
          </ife-col>
          <ife-col>
            <data-or-na v-model="person.phoneNumber" />
          </ife-col>
        </ife-row>

        <ife-row>
          <ife-col>
            Company:
          </ife-col>
          <ife-col>
            <data-or-na
              v-model="person.company"
              field="name"
            />
          </ife-col>
        </ife-row>

        <ife-row>
          <ife-col>
            Country:
          </ife-col>
          <ife-col>
            <data-or-na
              v-model="person.country"
              field="display"
            />
          </ife-col>
        </ife-row>

        <ife-row>
          <ife-col>
            Background:
          </ife-col>
          <ife-col>
            <b-checkbox
              v-model="options.background"
              switch
            />
          </ife-col>
        </ife-row>
      </div>
      <div
        v-else
        class="mt-3"
      >
        <b-alert
          variant="info"
          show
        >
          You have not been assigned to a Contact
        </b-alert>
      </div>
    </b-card>
  </div>
</template>

<script>
import DataOrNA from '@/Client/Component/Common/Output/DataOrNA';
import UserOptions from '@/Client/Model/UserOptions';
import UserPreferences from '@/Client/Model/UserPreferences';

export default {
  name: 'User',
  components: {
    'data-or-na': DataOrNA,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      show: false,
      person: null,
      edit: false,
      options: UserOptions,
    };
  },
  watch: {
    'options.background': {
      handler(value) {
        UserPreferences.setBackgroundPreference(value);
      },
    },
  },
  apollo: {
    person: {
      query: require('@/graphql/Query/Item/Person.graphql'),
      variables() {
        return {
          id: this.user.person,
        };
      },
      skip() {
        return !this.user.person;
      },
      update: function(data) {
        const obj = {...data.person};

        delete obj.__typename;
        delete obj.fullName;

        return obj;
      },
    },
  },
};
</script>

<style scoped>

</style>
