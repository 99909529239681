export default {
  state: {
    status: '',
    displayResolutions: [],
  },
  mutations: {
    displayResolution_request(state) {
      state.status = 'loading';
    },
    displayResolution_success(state, resolutions) {
      state.status = 'success';
      state.displayResolutions = resolutions;
    },
    displayResolution_error(state) {
      state.status = 'error';
    },
  },
  actions: {
    setDisplayResolutions({commit}, displayResolutions) {
      commit('displayResolution_success', displayResolutions);
    },
  },
  getters: {
    displayResolutions: (state) => {
      return state.displayResolutions;
    },
  },
};
