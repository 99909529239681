import Vue from 'vue';
import VueApollo from 'vue-apollo';
import {createApolloClient, restartWebsockets} from 'vue-cli-plugin-apollo/graphql-client';
import {setContext} from 'apollo-link-context';
import {onError} from 'apollo-link-error';
import {ApolloLink} from 'apollo-link';
import store from '@/Client/Store';
import {InMemoryCache} from 'apollo-cache-inmemory';

// Install the vue plugin
Vue.use(VueApollo);

// Name of the localStorage item
const AUTH_TOKEN = 'apollo-token';

// Http endpoint
const httpEndpoint = process.env.CORE_API_URL + '/api/graphql';

const authLink = setContext(async (_, {headers}) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  // Return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token || '',
    },
  };
});

const onErrorLink = onError(({graphQLErrors, networkError}) => {
  // We log every GraphQL errors
  if (graphQLErrors) {
    graphQLErrors.map(({message, locations, path}) =>
      console.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );
  }
  // When a 401 error occur, we log-out the user.
  if (networkError) {
    if (networkError.statusCode === 401) {
      store.dispatch('logout').then((response) => {
        window.location.href = '/login';
      });
    }
  }
});

const link = ApolloLink.from([
  authLink,
  onErrorLink,
]);

const cache = new InMemoryCache();

// Config
const defaultOptions = {
  link: link,

  // You can use `https` for secure connection (recommended in production)
  httpEndpoint,
  // You can use `wss` for secure connection (recommended in production)
  // Use `null` to disable subscriptions
  // wsEndpoint: process.env.VUE_APP_GRAPHQL_WS || 'ws://localhost:4000/graphql',
  wsEndpoint: null,
  // LocalStorage token
  tokenName: AUTH_TOKEN,
  // Enable Automatic Query persisting with Apollo Engine
  persisting: false,
  // Use websockets for everything (no HTTP)
  // You need to pass a `wsEndpoint` for this to work
  websocketsOnly: false,
  // Is being rendered on the server?
  ssr: false,

  cache: cache,

  // Override default apollo link
  // note: don't override httpLink here, specify httpLink options in the
  // httpLinkOptions property of defaultOptions.
  // link: myLink

  // Override default cache
  // cache: myCache

  // Override the way the Authorization header is set
  // getAuth: (tokenName) => ...

  // Additional ApolloClient options
  // apollo: { ... }

  // Client local data (see apollo-link-state)
  // clientState: { resolvers: { ... }, defaults: { ... } }
};

// Create apollo client
export const {apolloClient} = createApolloClient({
  ...defaultOptions,
  // ...options,
});

// Call this in the Vue app file
export function createProvider(options = {}) {
  // apolloClient.wsClient = wsClient

  // Create vue apollo provider
  const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        // fetchPolicy: 'cache-and-network',
      },
    },
    errorHandler(error) {
      console.error('Error:', error.message);
    },
  });

  return apolloProvider;
}

// Manually call this when user log in
export async function onLogin(apolloClient, token) {
  if (typeof localStorage !== 'undefined' && token) {
    localStorage.setItem(AUTH_TOKEN, token);
  }
  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient);
  try {
    await apolloClient.resetStore();
  } catch (e) {
    console.warn('Error on cache reset (login):', e.message);
  }
}

// Manually call this when user log out
export async function onLogout(apolloClient) {
  if (typeof localStorage !== 'undefined') {
    localStorage.removeItem(AUTH_TOKEN);
  }
  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient);
  try {
    await apolloClient.resetStore();
  } catch (e) {
    console.warn('Error on cache reset (logout):', e.message);
  }
}
