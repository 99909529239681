<template>
  <div>
    <b-form-input
      v-model="searchInput"
      style="width:250px"
      size="sm"
      class="mr-sm-2"
      placeholder="Search for title, actor, etc."
      @keydown.enter.prevent.stop="search"
    />
    <b-btn
      size="sm"
      class="my-2 my-sm-0"
      @click="search"
    >
      Search
    </b-btn>

    <ife-modal
      id="search-results"
      size="xl"
      :title="`Search Results - ${contentList.length} matches for '${searchInput}'`"
      hide-footer
    >
      <b-card-body v-if="0 < contentList.length">
        <div
          v-if="!navigating"
        >
          <b-input-group>
            <b-input
              v-model="titleFilter"
              placeholder="Filter by title"
            />
            <b-input-group-append>
              <b-select
                v-model="typeFilter"
                :options="types"
              >
                <template #first>
                  <b-select-option :value="null">
                    All Types
                  </b-select-option>
                </template>
              </b-select>
            </b-input-group-append>
          </b-input-group>
          <b-table
            borderless
            :items="filteredContent"
            striped
            hover
            :fields="searchResultFields"
            small
            per-page="30"
            :current-page="currentPage"
            @row-clicked="navigateTo"
          >
            <template #cell(fullTitle)="item">
              <span v-html="highlight(item.value)" />
            </template>
            <template #cell(originalTitle)="item">
              <span v-html="highlight(item.value)" />
            </template>
            <template #cell(genre)="item">
              <badge-collection
                :items="item.value"
                item-key="defaultName"
              />
            </template>
          </b-table>
          <b-pagination
            v-if="filteredContent.length > 30"
            v-model="currentPage"
            :total-rows="filteredContent.length"
            per-page="30"
          />
        </div>

        <ife-loading-card
          v-else
          headless
          :text="`Navigating to '${navigating}'`"
        />
      </b-card-body>

      <b-card-body v-else>
        <b-alert
          show
          variant="info"
          class="text-center"
        >
          There are no results matching "{{ searchInput }}"
        </b-alert>
      </b-card-body>

      <ife-button-tray
        cancel
        @cancel="$bvModal.hide('search-results')"
      />
    </ife-modal>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import IFEModal from '@/Client/Component/Common/Wrapper/IFEModal';
import BadgeCollection from '@/Client/Component/Common/Output/BadgeCollection';

export default {
  name: 'GlobalSearch',
  components: {
    'badge-collection': BadgeCollection,
    'ife-modal': IFEModal,
  },
  data() {
    return {
      searchInput: null,
      contentList: [],
      currentPage: 1,
      navigating: false,
      paginationKey: 0,
      titleFilter: '',
      typeFilter: null,
      searchResultFields: [
        {label: 'Title', key: 'fullTitle'},
        {label: 'Orig. Title', key: 'originalTitle'},
        'year',
        'genre',
        {
          key: 'contentType.name',
          label: 'Type',
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'movieContentType',
      'televisionContentType',
      'episodeContentType',
      'albumContentType',
      'shortContentType',
    ]),
    filteredContent() {
      return this.contentList.filter((content) => {
        if (0 === this.titleFilter.length && null === this.typeFilter) {
          return true;
        }

        const titleFilter = 0 === this.titleFilter.length ||
          content.fullTitle.toLowerCase().includes(this.titleFilter.toLowerCase());
        const typeFilter = null === this.typeFilter || content.contentType.name === this.typeFilter;

        return titleFilter && typeFilter;
      });
    },
    types() {
      return this.contentList.map((content) => content.contentType.name)
          .filter((type, index, array) => array.indexOf(type) === index)
          .sort();
    },
  },
  methods: {
    highlight(text) {
      if (!this.searchInput) {
        return text;
      }

      const words = this.searchInput.trim().split(' ');

      for (let i = 0; i < words.length; i++) {
        const check = new RegExp(words[i], 'ig');

        text = text.toString().replace(check, function(matchedText) {
          return '<span style="background-color: yellow;">' + matchedText + '</span>';
        });
      }

      return text;
    },
    navigateTo(item) {
      this.$router.push(item.id);
      this.$bvModal.hide('search-results');
      this.searchInput = null;
      this.searchResults = [];
    },
    search() {
      if (this.searchInput.length > 0) {
        this.axios.get(
            '/global-search',
            {params: {searchTerm: this.searchInput}},
        ).then((response) => {
          const contents = Object.values(this.standardiseResponse(response.data));
          this.$bvModal.show('search-results');
          this.contentList = contents;
          this.currentPage = 1;
          this.titleFilter = '';
          this.typeFilter = null;
          if (1 === contents.length) {
            this.navigating = `${contents[0].fullTitle} (${contents[0].year})`;
            setTimeout(() => {
              this.$router.push(contents[0].id);
              this.$bvModal.hide('search-results');
              this.navigating = false;
            }, 750);
          }
        }).catch((err) => {
          this.$log.error(err);
        });
      }
    },
  },
};
</script>

<style scoped>

</style>
