export default {
  state: {
    status: '',
    contentTypes: {},
  },
  mutations: {
    otherContentType_request(state) {
      state.status = 'loading';
    },
    otherContentType_success(state, contentTypes) {
      state.status = 'success';
      state.contentTypes = contentTypes;
    },
    otherContentType_error(state) {
      state.status = 'error';
    },
  },
  actions: {
    setOtherContentTypes({commit}, contentTypes) {
      commit('otherContentType_success', contentTypes);
    },
  },
  getters: {
    otherContentType: (state) => {
      return state.contentTypes;
    },
  },
};
