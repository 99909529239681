<template>
  <div>
    <video
      id="backgroundVideo"
      autoplay=""
      muted=""
      loop=""
    >
      <source
        :src="background"
        type="video/mp4"
      >
    </video>
  </div>
</template>

<script>
export default {
  name: 'BackgroundVideo',
  props: {
    background: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>

#backgroundVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: -1;
}

</style>
