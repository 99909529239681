export default {
  state: {
    status: '',
    personTypes: [],
    actorType: null,
    directorType: null,
    contactType: null,
    error: null,
  },
  mutations: {
    personType_request(state) {
      state.status = 'loading';
    },
    personType_full_list(state, personTypes) {
      state.personTypes = personTypes;
    },
    personType_actor_id(state, actorTypeId) {
      state.actorType = actorTypeId;
    },
    personType_director_id(state, directorTypeId) {
      state.directorType = directorTypeId;
    },
    personType_contact_id(state, contactTypeId) {
      state.contactType = contactTypeId;
    },
    personType_success(state) {
      state.status = 'success';
    },
    personType_error(state, error) {
      state.status = 'error';
      state.error = error;
    },
  },
  actions: {
    setPersonTypes({commit}, personTypes) {
      const types = [];
      personTypes.forEach((type) => {
        switch (type.code) {
          case 'AC':
            commit('personType_actor_id', type.id);
            break;
          case 'DIR':
            commit('personType_director_id', type.id);
            break;
          case 'CC':
            commit('personType_contact_id', type.id);
            break;
        }

        types.push({
          id: type.id,
          name: type.name,
          code: type.code,
        });
      });

      commit('personType_full_list', types);
      commit('personType_success');
    },
  },
  getters: {
    personTypes: (state) => {
      return state.personTypes;
    },
    actorType: (state) => {
      return state.actorType;
    },
    directorType: (state) => {
      return state.directorType;
    },
    contactType: (state) => {
      return state.contactType;
    },
  },
};
