export default {
  methods: {
    getSubtitleDisplay(subtitle) {
      return subtitle.language.code +
        (subtitle.hardcoded ? ' [ENC]' : '') +
        (subtitle.closedCaption ? ' [CC]' : '') +
        (subtitle.sdh ? ' [SDH]' : '');
    },
  },
};
