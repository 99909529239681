import {mapGetters} from 'vuex';

export default {
  computed: {
    ...mapGetters(['user']),
  },
  methods: {
    hasContentOwnership(content) {
      if (this.hasPermission('ROLE_ADMIN')) {
        return true;
      }

      if ('undefined' !== typeof content.primaryOwner) {
        return this.user.company === content.primaryOwner?.id;
      }

      for (const owner of content.dataOwners) {
        if (owner.dataOwners === this.user.company) {
          return true;
        }
      }

      return false;
    },
  },
};
