export default {
  state: {
    status: '',
    imageFileExtensions: [],
  },
  mutations: {
    imageFileExtension_request(state) {
      state.status = 'loading';
    },
    imageFileExtension_success(state, imageFileExtensions) {
      state.status = 'success';
      state.imageFileExtensions = imageFileExtensions;
    },
    imageFileExtension_error(state) {
      state.status = 'error';
    },
  },
  actions: {
    setImageFileExtensions({commit}, imageFileExtensions) {
      commit('imageFileExtension_success', imageFileExtensions);
    },
  },
  getters: {
    imageFileExtensions: (state) => {
      return state.imageFileExtensions;
    },
  },
};
