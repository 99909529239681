export default {
  state: {
    status: '',
    audioBitrates: [],
  },
  mutations: {
    audioBitrate_request(state) {
      state.status = 'loading';
    },
    audioBitrate_success(state, bitrates) {
      state.status = 'success';
      state.audioBitrates = bitrates;
    },
    audioBitrate_error(state) {
      state.status = 'error';
    },
  },
  actions: {
    setAudioBitrates({commit}, audioBitrates) {
      commit('audioBitrate_success', audioBitrates);
    },
  },
  getters: {
    audioBitrates: (state) => {
      return state.audioBitrates;
    },
  },
};
