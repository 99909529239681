export default {
  state: {
    status: '',
    contentServiceProviders: [],
  },
  mutations: {
    csp_request(state) {
      state.status = 'loading';
    },
    csp_success(state, contentServiceProviders) {
      state.status = 'success';
      state.contentServiceProviders = contentServiceProviders;
    },
    csp_error(state) {
      state.status = 'error';
    },
  },
  actions: {
    setContentServiceProviders({commit}, contentServiceProviders) {
      commit('csp_success', contentServiceProviders);
    },
  },
  getters: {
    availableContentServiceProviders: (state) => {
      return state.contentServiceProviders;
    },
  },
};
