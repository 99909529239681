export default {
  state: {
    status: '',
    types: [],
    visibilityTypes: [],
  },
  mutations: {
    imageType_request(state) {
      state.status = 'loading';
    },
    imageType_success(state, types) {
      state.status = 'success';
      state.types = types;
    },
    imageType_error(state) {
      state.status = 'error';
    },
    imageVisibilityType_request(state) {
      state.status = 'loading';
    },
    imageVisibilityType_success(state, visibilityTypes) {
      state.status = 'success';
      state.visibilityTypes = visibilityTypes;
    },
    imageVisibilityType_error(state) {
      state.status = 'error';
    },
  },
  actions: {
    setImageTypes({commit}, types) {
      commit('imageType_success', types);
    },
    setImageVisibilityTypes({commit}, visibilityTypes) {
      commit('imageVisibilityType_success', visibilityTypes);
    },
  },
  getters: {
    imageTypes: (state) => {
      return state.types;
    },
    imageVisibilityTypes: (state) => {
      return state.visibilityTypes;
    },
  },
};
