export default {
  state: {
    status: '',
    videoBitrates: [],
  },
  mutations: {
    videoBitrate_request(state) {
      state.status = 'loading';
    },
    videoBitrate_success(state, bitrates) {
      state.status = 'success';
      state.videoBitrates = bitrates;
    },
    videoBitrate_error(state) {
      state.status = 'error';
    },
  },
  actions: {
    setVideoBitrates({commit}, videoBitrates) {
      commit('videoBitrate_success', videoBitrates);
    },
  },
  getters: {
    videoBitrates: (state) => {
      return state.videoBitrates;
    },
  },
};
