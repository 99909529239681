const AVAILABILITY = 'AVAIL';
const CONTENT = 'CO';
const INVOICE = 'INV';
const LICENSE = 'LIC';
const LICENSE_ORDER = 'ORD';
const LICENSE_ORDER_PART = 'ORDP';
const MATERIAL_ORDER = 'MATO';
const MATERIAL_ORDER_ITEM = 'MATOI';
const SELECTION = 'SEL';
const SELECTIONGROUP = 'SELGR';

/**
 * Class used to expose specific country codes
 */
export default class EntityTypeCode {
  /**
   * Static getter for the entity type Content;
   * @return {string}
   * @constructor
   */
  static get CONTENT() {
    return CONTENT;
  }

  /**
   * Static getter for the entity type License
   * @return {string}
   * @constructor
   */
  static get LICENSE() {
    return LICENSE;
  }

  /**
   * Static getter for the entity type Invoice
   * @return {string}
   * @constructor
   */
  static get INVOICE() {
    return INVOICE;
  }

  /**
   * Static getter for the entity type License Order
   * @return {string}
   * @constructor
   */
  static get LICENSE_ORDER() {
    return LICENSE_ORDER;
  }

  /**
   * Static getter for the entity type Availability
   * @return {string}
   * @constructor
   */
  static get AVAILABILITY() {
    return AVAILABILITY;
  }

  /**
   * Static getter for the entity type Material Order
   * @return {string}
   * @constructor
   */
  static get MATERIAL_ORDER() {
    return MATERIAL_ORDER;
  }

  /**
   * Static getter for the entity type Material Order Item
   * @return {string}
   * @constructor
   */
  static get MATERIAL_ORDER_ITEM() {
    return MATERIAL_ORDER_ITEM;
  }

  /**
   * Static getter for the entity type Selection
   * @return {string}
   * @constructor
   */
  static get SELECTION() {
    return SELECTION;
  }

  /**
   * Static getter for the entity type Selection Group
   * @return {string}
   * @constructor
   */
  static get SELECTION_GROUP() {
    return SELECTIONGROUP;
  }

  /**
   * Static getter for the entity type License Order Part
   * @return {string}
   * @constructor
   */
  static get LICENSE_ORDER_PART() {
    return LICENSE_ORDER_PART;
  }
};
