<template>
  <ife-row
    v-if="hasCompanyLogin"
    align-h="center"
  >
    <ife-col
      cols="12"
      md="7"
      lg="5"
      :class="'mt-4' + (hasCompanyLogin ? ' text-center' : '')"
    >
      <img
        v-if="hasCompanyLogo"
        id="companyLogo"
        alt="Company Logo"
        :class="companyLogin.cssClass"
        :src="getCompanyLogo"
      >
    </ife-col>
  </ife-row>
</template>

<script>
import UserPreferences from '@/Client/Model/UserPreferences';

export default {
  name: 'ShowcaseCompanyLogo',
  data() {
    return {

    };
  },
  computed: {
    companyLogin() {
      return UserPreferences.getCompanyLogin();
    },
    cssClass() {
      return this.hasCompanyLogin ? this.companyLogin.cssClass : '';
    },
    getCompanyLogo() {
      return require(
          '../../assets/img/logo/' +
          this.companyLogin.logo,
      );
    },
    hasCompanyLogin() {
      return null !== this.companyLogin;
    },
    hasCompanyLogo() {
      return this.hasCompanyLogin && this.companyLogin.hasOwnProperty('logo');
    },
  },
};
</script>

<style scoped>
  #companyLogo {
    padding-top: 70px;
    padding-bottom: 50px;
    width: 300px;
  }
</style>
