/**
 * Methods for cloning objects
 * deepCopy - Allows an object to be deep cloned. The spread operator retains
 * the reference to children in the object so another cloning method is required
 * to ensure the entire object is cloned and any changes to children are not reflected
 * anywhere else in the application
 */
export default {
  methods: {
    deepCopy(object) {
      return JSON.parse(JSON.stringify(object));
    },
  },
};
