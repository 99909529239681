import Vue from 'vue';

export default {
  state: {
    status: '',
    cspContacts: [],
  },
  mutations: {
    csp_contact_request(state) {
      state.status = 'loading';
    },
    csp_contact_success(state, cspContacts) {
      state.status = 'success';
      state.cspContacts = cspContacts;
    },
    csp_contact_error(state) {
      state.status = 'error';
    },
  },
  actions: {
    cspContacts({commit}) {
      if (this.getters.availableContentServiceProviderContacts.length <= 0) {
        return new Promise((resolve, reject) => {
          commit('csp_contact_request');
          Vue.axios.get(
              '/content_service_provider_contacts',
              {params: {'order[contact.firstName]': 'asc'},
              })
              .then((resp) => {
                const cspContacts = resp.data['hydra:member'];
                commit('csp_contact_success', cspContacts);
                resolve(resp);
              })
              .catch((err) => {
                commit('csp_contact_error');
                reject(err);
              });
        });
      }
    },
  },
  getters: {
    availableContentServiceProviderContacts: (state) => {
      return state.cspContacts;
    },
  },
};
