export default {
  state: {
    status: '',
    videoEncodings: [],
  },
  mutations: {
    videoEncoding_request(state) {
      state.status = 'loading';
    },
    videoEncoding_success(state, encodings) {
      state.status = 'success';
      state.videoEncodings = encodings;
    },
    videoEncoding_error(state) {
      state.status = 'error';
    },
  },
  actions: {
    setVideoEncodings({commit}, videoEncodings) {
      commit('videoEncoding_success', videoEncodings);
    },
  },
  getters: {
    videoEncodings: (state) => {
      return state.videoEncodings;
    },
  },
};
