import CompanyType from '@/Client/Store/Constants/CompanyType';
import SelectionState from '@/Client/Store/Constants/SelectionState';
import ContentTypeCode from '@/Client/Store/Constants/ContentTypeCode';
import CountryCode from '@/Client/Store/Constants/CountryCode';
import Currency from '@/Client/Store/Constants/Currency';

export default {
  computed: {
    // Company Types
    companyType() {
      return CompanyType;
    },

    // Status sheet states
    selectionState() {
      return SelectionState;
    },

    // Content type codes
    contentTypeCode() {
      return ContentTypeCode;
    },

    // Specific Country codes
    countryCode() {
      return CountryCode;
    },

    // Specific Currencies (default)
    currencyName() {
      return Currency;
    },
  },
};
