export default {
  state: {
    status: '',
    studios: [],
  },
  mutations: {
    studio_request(state) {
      state.status = 'loading';
    },
    studio_success(state, studios) {
      state.status = 'success';
      state.studios = studios;
    },
    studio_error(state) {
      state.status = 'error';
    },
  },
  actions: {
    setStudios({commit}, studios) {
      commit('studio_success', studios);
    },
  },
  getters: {
    availableStudios: (state) => {
      return state.studios;
    },
  },
};
