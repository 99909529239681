<template>
  <b-overlay
    :show="appLoading"
  >
    <navigation v-if="user.hasOwnProperty('id')" />

    <div v-if="!appLoading && !appLoadingError">
      <ife-container
        v-if="!authorised"
        class="mt-3"
      >
        <b-alert
          variant="warning"
          show
        >
          You do not have permission to access this page
        </b-alert>
      </ife-container>

      <ife-container
        v-else
        fluid
        class="mt-3"
      >
        <router-view />
      </ife-container>
    </div>

    <div v-else-if="appLoadingError">
      <ife-container
        class="mt-3"
      >
        <b-alert
          variant="warning"
          show
        >
          Something went wrong while setting up IFE Data. Please refresh the page to try again.
          <br>
          <br>
          {{ appLoadingError }}
        </b-alert>
      </ife-container>
    </div>

    <ife-container
      v-else
      fluid
      class="min-vh-100"
    />

    <template #overlay>
      <div
        class="text-center"
        style="min-height: 500px;"
      >
        <b-icon
          icon="stopwatch"
          font-scale="3"
          animation="cylon"
        />
        <p id="label">
          Initialising Application Data
        </p>
      </div>
    </template>
  </b-overlay>
</template>

<script>
import Navigation from '@/Client/Component/Navigation';
import {mapGetters} from 'vuex';

export default {
  name: 'Authenticated',
  components: {
    'navigation': Navigation,
  },
  computed: {
    ...mapGetters(['appLoading', 'appLoadingError', 'user', 'isRefreshingToken']),
    authorised() {
      if (this.$route.meta.hasOwnProperty('permission') && !this.hasPermission(this.$route.meta.permission)) {
        return false;
      }

      if (this.$route.meta.hasOwnProperty('companyAuthorisation')) {
        const companyId = this.$route.meta.companyId(this.$route);
        if (!this.isInCompany(this.user, companyId)) {
          return false;
        }
      }

      return true;
    },
  },
  mounted() {
    // this gets around the problem of new app instances (revisits, new tabs, etc.)
    // and it 'waiting' without the refresh_token_complete event being dispatched
    if (!this.isRefreshingToken) {
      this.$store.dispatch('refreshToken');
    }
  },
};
</script>

<style scoped>

</style>
