export default {
  methods: {
    /**
     * JavaScript uses different false equivalence to PHP, mainly empty arrays and objects evaluate to true
     * @param value
     */
    isFalseyValue(value) {
      // Handle: 0, "", undefined, null, NaN, false
      if (!value) {
        return true;
      }

      // Handle: []
      if (Array.isArray(value) && 0 === value.length) {
        return true;
      }

      // Handle: {}
      if ('object' === typeof value && 0 === Object.values(value).length) {
        return true;
      }

      return false;
    },
    isTrueyValue(value) {
      return !this.methods.isFalseyValue(value);
    },
  },
};
