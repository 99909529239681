export default {
  methods: {
    setFavicon(favicon) {
      document.querySelector('link[rel*="icon"]').href = favicon;
    },
    resetFavicon() {
      document.querySelector('link[rel*="icon"]').href = require(
          '../../assets/img/favicon.png',
      );
    },
  },
};
