<template>
  <ife-container
    id="main"
    :class="cssClass"
    fluid
  >
    <showcase-company-logo />
    <ife-row align-h="center">
      <ife-col
        cols="12"
        md="7"
        lg="5"
        class="mt-4"
      >
        <ife-card
          v-if="complete"
          title="Thank you"
          :class="`ife-card ${cssClass}`"
        >
          <p>
            Your request to reset your password has been received.
            <br>The request will be processed if there is an enabled account
            for <a :href="`mailto:${email}`">{{ email }}</a>.
            <br>Please check your email for further instructions.
          </p>
        </ife-card>
        <b-overlay
          v-else
          :show="loading"
        >
          <ife-card
            title="Forgotten your password?"
            :class="`ife-card ${cssClass}`"
          >
            <b-alert
              v-if="error"
              variant="danger"
              show
            >
              {{ error }}
            </b-alert>
            <validation-observer v-slot="v">
              <ife-form
                @submit="v.handleSubmit(submit)"
              >
                <ife-simple-input
                  v-model="email"
                  :label-cols="4"
                  hide-label
                  label=""
                  placeholder="Email Address"
                  type="email"
                  rules="required"
                />

                <b-btn
                  size="sm"
                  variant="primary"
                  type="submit"
                >
                  Reset Password
                </b-btn>
                <b-btn
                  size="sm"
                  variant="secondary"
                  @click="goBackToLogin()"
                >
                  Cancel
                </b-btn>
              </ife-form>
            </validation-observer>
          </ife-card>
        </b-overlay>
      </ife-col>
    </ife-row>
  </ife-container>
</template>

<script>
import ShowcaseCompanyLogo from '@/Client/Page/ShowcaseCompanyLogo.vue';
import UserPreferences from '@/Client/Model/UserPreferences';

export default {
  name: 'ForgotPassword',
  components: {
    'showcase-company-logo': ShowcaseCompanyLogo,
  },
  data() {
    return {
      complete: false,
      email: null,
      error: null,
      loading: false,
    };
  },
  computed: {
    companyLogin() {
      return UserPreferences.getCompanyLogin();
    },
    cssClass() {
      return this.hasCompanyLogin ? this.companyLogin.cssClass : '';
    },
    hasCompanyLogin() {
      return null !== this.companyLogin;
    },
  },
  methods: {
    goBackToLogin() {
      const name = this.hasCompanyLogin ? 'companyLogin' : 'login';
      const params = this.hasCompanyLogin ? {company: this.companyLogin.name} : {};

      this.$router.push({name: name, params: params});
    },
    submit() {
      this.error = null;
      this.loading = true;

      this.axios.post(
          `${process.env.CORE_API_URL}/reset-password`,
          {
            'email': this.email,
          },
      )
          .then(() => {
            this.complete = true;
          })
          .catch(() => {
            this.error = 'Unable to reset your password at this time. Please try again later.';
          })
          .finally(() => {
            this.loading = false;
          });
    },
  },
};
</script>

<style scoped>

</style>
