export default {
  methods: {
    getPaginationData(obj) {
      if (obj.paginationInfo) {
        return obj.paginationInfo;
      }
    },
    standardiseResponse(obj) {
      const standardisedResponse = {};
      for (const key in obj) {
        if (obj[key] instanceof Object) {
          if (obj[key].hasOwnProperty('collection')) {
            standardisedResponse[key] = obj[key].collection;
            continue;
          }

          if (obj[key].hasOwnProperty('edges')) {
            standardisedResponse[key] = obj[key].edges.map((item) => {
              return item.node;
            });
            continue;
          }
        }
        standardisedResponse[key] = obj[key];
      }
      return standardisedResponse;
    },
    flattenRequest(object) {
      const flattened = {};
      if (!object) {
        return false;
      }

      const temp = {...object};

      if (temp.__typename) {
        delete temp.__typename;
      }

      if (temp._id) {
        delete temp._id;
      }

      if (temp.latestEntityStateHistory) {
        delete temp.latestEntityStateHistory;
      }

      for (const key in temp) {
        if (!temp.hasOwnProperty(key)) {
          continue;
        }

        if (!(temp[key] instanceof Object) && !(temp[key] instanceof Array)) {
          // Reset primitives to null if they're empty strings
          if ('' !== temp[key]) {
            flattened[key] = temp[key];
            continue;
          }
          flattened[key] = null;
        }

        if (temp[key] instanceof Object && temp[key].hasOwnProperty('id')) {
          flattened[key] = temp[key].id;
          continue;
        }

        if (temp[key].hasOwnProperty('collection')) {
          flattened[key] = temp[key].collection.map((child) => {
            return child.id;
          });
          continue;
        }

        if (temp[key].hasOwnProperty('edges')) {
          let arr = temp[key].edges.map((child) => {
            return child.node.id;
          });

          // This may need to be removed - if this property exists then it should be modified accordingly
          // With this method, removal of all existing list items is impossible
          if (1 > arr.length) {
            arr = null;
          }

          flattened[key] = arr;
          continue;
        }

        if (temp[key] instanceof Array && temp[key].length === 0) {
          flattened[key] = [];
          continue;
        }

        if (temp[key] instanceof Array && temp[key][0] !== undefined && temp[key][0].hasOwnProperty('id')) {
          let arr = temp[key].map((child) => {
            return child.id;
          });

          if (1 > arr.length) {
            arr = null;
          }

          flattened[key] = arr;
        }
      }

      return flattened;
    },
  },
};
