export default {
  state: {
    status: '',
    states: [],
  },
  mutations: {
    entity_state_list_request(state) {
      state.status = 'sending';
    },
    entity_state_list_success(state, states) {
      state.status = 'success';
      state.states = states;
    },
    entity_state_list_error(state) {
      state.status = 'error';
    },
  },
  actions: {
    setEntityStates({commit}, entityStates) {
      commit('entity_state_list_success', entityStates);
    },
  },
  getters: {
    availableStates: (state) => {
      return state.states;
    },
    draftState: (state) => {
      return state.states.filter((state) => {
        return state.code === 'DR';
      })[0];
    },
    searchByEntityType: (state) => {
      return (keyword) => state.states.filter((state) => {
        return state.entityType.id = keyword;
      });
    },
    searchByTypeAndCode: (state) => {
      return (type, code) => state.states.filter((state) => {
        return state.code === code && state.entityType.id === type;
      });
    },
    searchByTypeCodeAndStateCode: (state) => {
      return (typeCode, stateCode) => state.states.filter((state) => {
        return state.code === stateCode && state.entityType.code === typeCode;
      });
    },
    draftStateIRI: (state) => {
      const tempState = state.states.filter((state) => {
        return state.code === 'DR';
      });

      if (tempState.length > 0) {
        return tempState[0]['@id'];
      }
      return null;
    },
  },
};
