export default {
  state: {
    status: '',
    airlines: [],
  },
  mutations: {
    contentServiceProviderAirlines_request(state) {
      state.status = 'loading';
    },
    contentServiceProviderAirlines_success(state, airlines) {
      state.status = 'success';
      state.airlines = airlines;
    },
    contentServiceProviderAirlines_error(state) {
      state.status = 'error';
    },
  },
  actions: {
    setContentServiceProviderAirlines({commit}, airlines) {
      commit('contentServiceProviderAirlines_success', airlines);
    },
  },
  getters: {
    contentServiceProviderAirlines: (state) => {
      return state.airlines;
    },
  },
};
