export default {
  state: {
    status: '',
    audioEncodings: [],
  },
  mutations: {
    audioEncoding_request(state) {
      state.status = 'loading';
    },
    audioEncoding_success(state, encodings) {
      state.status = 'success';
      state.audioEncodings = encodings;
    },
    audioEncoding_error(state) {
      state.status = 'error';
    },
  },
  actions: {
    setAudioEncodings({commit}, audioEncodings) {
      commit('audioEncoding_success', audioEncodings);
    },
  },
  getters: {
    audioEncodings: (state) => {
      return state.audioEncodings;
    },
  },
};
