export default {
  state: {
    status: '',
    appDataComplete: false,
    appDataError: null,
    appDataLoading: false,
    refreshTokenComplete: false,
  },
  mutations: {
    app_data_loading(state) {
      state.status = 'app_data_loading';
      state.appDataError = null;
      state.appDataComplete = false;
      state.appDataLoading = true;
    },
    app_data_complete(state) {
      state.status = 'success';
      state.appDataComplete = true;
      state.appDataLoading = false;
    },
    app_data_error(state, appDataError) {
      state.status = 'error';
      state.appDataError = appDataError;
      state.appDataLoading = false;
    },
    refresh_token_complete(state) {
      state.status = 'success';
      state.refreshTokenComplete = true;
      state.appDataLoading = false;
      state.isRefreshingToken = false;
    },
  },
  actions: {
    setAppDataLoading({commit}) {
      commit('app_data_loading');
    },
    setAppDataComplete({commit}) {
      commit('app_data_complete');
    },
    setAppDataError({commit}, appDataError) {
      commit('app_data_error', appDataError);
    },
    setRefreshTokenComplete({commit}) {
      commit('refresh_token_complete');
    },
  },
  getters: {
    appDataLoading: (state) => {
      return state.appDataLoading;
    },
    appLoading: (state) => {
      return (!state.appDataComplete || !state.refreshTokenComplete) && null === state.appDataError;
    },
    appLoadingError: (state) => {
      return state.appDataError;
    },
  },
};
