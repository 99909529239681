export default {
  state: {
    status: '',
    deliveryMethods: [],
  },
  mutations: {
    deliveryMethod_request(state) {
      state.status = 'loading';
    },
    deliveryMethod_success(state, bitrates) {
      state.status = 'success';
      state.deliveryMethods = bitrates;
    },
    deliveryMethod_error(state) {
      state.status = 'error';
    },
  },
  actions: {
    setDeliveryMethods({commit}, deliveryMethods) {
      commit('deliveryMethod_success', deliveryMethods);
    },
  },
  getters: {
    deliveryMethods: (state) => {
      return state.deliveryMethods;
    },
  },
};
