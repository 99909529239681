import SelectionTypeCode from '@/Client/Store/Constants/SelectionTypeCode';

export default {
  state: {
    status: '',
    selectionTypes: [],
    itemTypeId: null,
    groupTypeId: null,
    playlistTypeId: null,
    error: null,
  },
  mutations: {
    selectionType_request(state) {
      state.status = 'loading';
    },
    selectionType_full_list(state, selectionTypes) {
      state.selectionTypes = selectionTypes;
    },
    selectionType_item_id(state, movieId) {
      state.itemTypeId = movieId;
    },
    selectionType_group_id(state, televisionId) {
      state.groupTypeId = televisionId;
    },
    selectionType_playlist_id(state, seasonId) {
      state.playlistTypeId = seasonId;
    },
    selectionType_success(state) {
      state.status = 'success';
    },
    selectionType_error(state, error) {
      state.status = 'error';
      state.error = error;
    },
  },
  actions: {
    setSelectionTypes({commit}, selectionTypes) {
      const types = [];
      selectionTypes.forEach((type) => {
        switch (type.code) {
          case SelectionTypeCode.ITEM:
            commit('selectionType_item_id', type.id);
            break;
          case SelectionTypeCode.GROUP:
            commit('selectionType_group_id', type.id);
            break;
          case SelectionTypeCode.PLAYLIST:
            commit('selectionType_playlist_id', type.id);
            break;
        }

        types.push({
          id: type.id,
          name: type.name,
        });
      });

      commit('selectionType_full_list', types);
      commit('selectionType_success');
    },
  },
  getters: {
    itemSelectionType: (state) => {
      return state.itemTypeId;
    },
    groupSelectionType: (state) => {
      return state.groupTypeId;
    },
    playlistSelectionType: (state) => {
      return state.playlistTypeId;
    },
    selectionTypes: (state) => {
      return state.selectionTypes;
    },
  },
};
