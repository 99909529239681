import ContentTypeCode from '@/Client/Store/Constants/ContentTypeCode';

export default {
  state: {
    status: '',
    contentTypes: [],
    albumTypeId: null,
    trackTypeId: null,
    movieTypeId: null,
    televisionTypeId: null,
    seasonTypeId: null,
    episodeTypeId: null,
    shortTypeId: null,
    otherVideoItemTypeId: null,
    otherAudioItemTypeId: null,
    otherAudioGroupTypeId: null,
    otherAudioGroupItemTypeId: null,
    trailerContentTypeId: null,
  },
  mutations: {
    contentType_request(state) {
      state.status = 'loading';
    },
    contentType_full_list(state, contentTypes) {
      state.contentTypes = contentTypes;
    },
    contentType_movie_id(state, movieId) {
      state.movieTypeId = movieId;
    },
    contentType_television_id(state, televisionId) {
      state.televisionTypeId = televisionId;
    },
    contentType_season_id(state, seasonId) {
      state.seasonTypeId = seasonId;
    },
    contentType_episode_id(state, episodeId) {
      state.episodeTypeId = episodeId;
    },
    contentType_album_id(state, albumId) {
      state.albumTypeId = albumId;
    },
    contentType_track_id(state, trackId) {
      state.trackTypeId = trackId;
    },
    contentType_short_id(state, shortId) {
      state.shortTypeId = shortId;
    },
    contentType_otherVideoItem_id(state, otherVideoItemId) {
      state.otherVideoItemTypeId = otherVideoItemId;
    },
    contentType_otherAudioItem_id(state, otherAudioItemId) {
      state.otherAudioItemTypeId = otherAudioItemId;
    },
    contentType_otherAudioGroup_id(state, otherAudioGroupId) {
      state.otherAudioGroupTypeId = otherAudioGroupId;
    },
    contentType_otherAudioGroupItem_id(state, otherAudioGroupItemId) {
      state.otherAudioGroupItemTypeId = otherAudioGroupItemId;
    },
    contentType_trailer_id(state, trailerContentTypeId) {
      state.trailerContentTypeId = trailerContentTypeId;
    },
    contentType_success(state) {
      state.status = 'success';
    },
    contentType_error(state) {
      state.status = 'error';
    },
  },
  actions: {
    setContentTypes({commit}, contentTypes) {
      const types = [];
      contentTypes.forEach((type) => {
        switch (type.code) {
          case ContentTypeCode.MOVIE:
            commit('contentType_movie_id', type.id);
            break;
          case ContentTypeCode.TELEVISION:
            commit('contentType_television_id', type.id);
            break;
          case ContentTypeCode.SEASON:
            commit('contentType_season_id', type.id);
            break;
          case ContentTypeCode.EPISODE:
            commit('contentType_episode_id', type.id);
            break;
          case ContentTypeCode.ALBUM:
            commit('contentType_album_id', type.id);
            break;
          case ContentTypeCode.TRACK:
            commit('contentType_track_id', type.id);
            break;
          case ContentTypeCode.SHORT:
            commit('contentType_short_id', type.id);
            break;
          case ContentTypeCode.OTHER_VIDEO_ITEM:
            commit('contentType_otherVideoItem_id', type.id);
            break;
          case ContentTypeCode.OTHER_AUDIO_ITEM:
            commit('contentType_otherAudioItem_id', type.id);
            break;
          case ContentTypeCode.OTHER_AUDIO_GROUP:
            commit('contentType_otherAudioGroup_id', type.id);
            break;
          case ContentTypeCode.OTHER_AUDIO_GROUP_ITEM:
            commit('contentType_otherAudioGroupItem_id', type.id);
            break;
          case ContentTypeCode.TRAILER:
            commit('contentType_trailer_id', type.id);
            break;
        }

        types.push(type);
      });

      commit('contentType_full_list', types);
      commit('contentType_success');
    },
  },
  getters: {
    albumContentType: (state) => {
      return state.albumTypeId;
    },
    trackContentType: (state) => {
      return state.trackTypeId;
    },
    availableContentTypes: (state) => {
      return state.contentTypes;
    },
    movieContentType: (state) => {
      return state.movieTypeId;
    },
    televisionContentType: (state) => {
      return state.televisionTypeId;
    },
    seasonContentType: (state) => {
      return state.seasonTypeId;
    },
    episodeContentType: (state) => {
      return state.episodeTypeId;
    },
    shortContentType: (state) => {
      return state.shortTypeId;
    },
    otherVideoItemContentType: (state) => {
      return state.otherVideoItemTypeId;
    },
    otherAudioItemContentType: (state) => {
      return state.otherAudioItemTypeId;
    },
    otherAudioGroupContentType: (state) => {
      return state.otherAudioGroupTypeId;
    },
    otherAudioGroupItemContentType: (state) => {
      return state.otherAudioGroupItemTypeId;
    },
    trailerContentType: (state) => {
      return state.trailerContentTypeId;
    },
    contentTypes: (state) => {
      return state.contentTypes;
    },
  },
};
